const defaultState = {
  data: null,
  loading: false,
  error: false,
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case "LOGIN_LOADING":
    case "GET_USER_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS":
    case "GET_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "LOGIN_ERROR":
    case "GET_USER_ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
