import axios from "../../../api";

export function login(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "LOGIN_LOADING" });
    axios
      // .post("http://localhost:4000/google-test", options)
      .post("http://localhost:4000/google-list-calendar", options)
      // .post("http://localhost:4000/google-add-calendar", options)
      // .post("http://localhost:4000/google-add-event", options)
      // .post("http://localhost:4000/google-list-event", options)
      // .post("http://localhost:4000/google-delete-event", options)
      .then((response) => {
        console.log("response : ", response.data.data.items);
        console.log("response : ", response);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: response,
          //payload : null
        });
      })
      .catch(() => {
        dispatch({
          type: "LOGIN_ERROR",
        });
      });
  };
}
