import { Switch, Route } from "react-router-dom";

import AccountListing from "./Listing";
import AccountDetails from "./Details";
import AccountCreate from "./Create";

function Account() {
  return (
    <Switch>
      <Route exact path="/account" component={AccountListing} />
      <Route strict path="/account/detail" component={AccountDetails} />
      <Route strict path="/account/create" component={AccountCreate}></Route>
    </Switch>
  );
}

export default Account;
