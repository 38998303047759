import CalendarSelector from "../../../../../../Common/Calendar/CalendarSelector";
import Page from "../../../../../../Layout/Page";

function StudentsSearchCreateCalendar({ match }) {
  return (
    <>
      <Page>
        <CalendarSelector></CalendarSelector>
        {/* <CalendarSelector
          path={`/students/${match.params.studentsid}/search/`}
        ></CalendarSelector> */}
      </Page>
    </>
  );
}

export default StudentsSearchCreateCalendar;
