import { useState, useEffect } from "react";

import {
  Container,
  DayNumber,
  DayLabel,
  Content,
  TimeGrid,
  TimeGridLine,
  TimeGridLineEmpty,
  EventContainer,
  TopContainer,
  CurrentTimeContainer,
  ContentContainer,
  ContentContainer1,
} from "../Day/Styled";

const labelDays = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  7: "Dimanche",
};

const getCurrentDay = function (date) {
  return date.getDate();
};

const getCurrentLabel = function (date) {
  const day = date.getDay();
  return labelDays[day === 0 ? 7 : day];
};

const getTopPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTopeEventPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime() - 3600000;
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getToPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getHeight = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrentStart = new Date(event.startDate).getTime();
  const refCurrentEnd = new Date(event.endDate).getTime();
  const eventHeight = refCurrentEnd - refCurrentStart;
  const totalHeight = refEnd - refStart;
  const calcul = (100 * eventHeight) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTimeGrid = function (date, range) {
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setHours(8, 0, 0);
  endDate.setHours(22, 0, 0);
  const numberOfLine = (endDate.getTime() - startDate.getTime()) / range;
  const lines = [...new Array(numberOfLine)].map((_, index) => {
    return new Date(startDate.getTime() + range * index);
  });
  return { startDate, endDate, numberOfLine, lines };
};

function DayInstructor({
  currentDate = new Date(),
  displayDayNumber = true,
  displayTime = true,
  displayCurrentTime = false,
  type,
  timeGridDisplay = true,
  displayDayLabel = true,
  events = [],
  program,
}) {
  const [currentTimeContainer, setCurrentTimeContainer] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      new Date().getHours(),
      new Date().getMinutes()
    )
  );

  useEffect(() => {
    console.log("program : ", program);
    window.setInterval(() => {
      setCurrentTimeContainer(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          new Date().getHours(),
          new Date().getMinutes()
        )
      );
    }, 60000);
  }, [program, type, currentDate]);

  const currentDay = getCurrentDay(currentDate);
  const currentLabel = getCurrentLabel(currentDate);
  const range = 60000 * 60;
  const grid = getTimeGrid(currentDate, range);

  const now = {
    startDate: currentTimeContainer,
  };

  return (
    <>
      <Container test={displayTime === true}>
        <TopContainer>
          {displayDayNumber && (
            <DayNumber
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth() &&
                currentDate.getFullYear() === new Date().getFullYear()
                  ? "active"
                  : ""
              }
            >
              {currentDay}
            </DayNumber>
          )}
          {displayDayLabel && (
            <DayLabel
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth()
                  ? "active"
                  : ""
              }
            >
              {currentLabel}
            </DayLabel>
          )}
        </TopContainer>
        {timeGridDisplay && (
          <Content>
            <TimeGrid
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                console.log(e.clientY - rect.top - 10);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {grid.lines.map((line, index) => (
                <TimeGridLine
                  key={index}
                  style={{
                    top: getTopPosition([grid.startDate, grid.endDate], {
                      startDate: line,
                    }),
                  }}
                >
                  <span>
                    {displayTime &&
                      line.toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </span>
                  <TimeGridLineEmpty />
                </TimeGridLine>
              ))}
            </TimeGrid>
            <>
              {events
                .filter((item) => {
                  return (
                    new Date(item.startDate).getTime() >
                      new Date(grid.startDate).getTime() &&
                    new Date(item.endDate).getTime() <
                      new Date(grid.endDate).getTime()
                  );
                })
                .sort((a, b) => {
                  return (
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime() +
                    (new Date(a.endDate).getTime() -
                      new Date(b.endDate).getTime())
                  );
                })
                .map((event, index) => (
                  <EventContainer
                    key={index}
                    style={{
                      zIndex: index + 12,
                      top: `calc(${getTopeEventPosition(
                        [grid.startDate, grid.endDate],
                        event
                      )} + (21px / 2) - 1px)`,
                      height: getHeight([grid.startDate, grid.endDate], event),
                    }}
                    full={type !== "day"}
                  >
                    <ContentContainer>{event.label}</ContentContainer>
                    <ContentContainer1>
                      {`${new Date(event.startDate).toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })} - ${new Date(event.endDate).toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })}`}
                    </ContentContainer1>
                  </EventContainer>
                ))}
            </>
          </Content>
        )}
      </Container>
      {displayCurrentTime && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: `calc(100% - 80px)`,
            left: "0px",
            top: "65px",
            zIndex: 11,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CurrentTimeContainer
            style={{
              top: `calc(${getToPosition(
                [grid.startDate, grid.endDate],
                now
              )} + (21px / 2 ))`,
            }}
            data-time={currentTimeContainer.toLocaleString("fr-fr", {
              hour: "numeric",
              minute: "numeric",
            })}
          ></CurrentTimeContainer>
        </div>
      )}
    </>
  );
}

export default DayInstructor;
