import Page from "../../../../Layout/Page";
import CalendarAvailability from "../../../../Common/Calendar/CalendarAvailability";

function StudentsAvailability() {
  return (
    <>
      <Page>
        <CalendarAvailability></CalendarAvailability>
      </Page>
    </>
  );
}

export default StudentsAvailability;
