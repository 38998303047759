import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 800;
  font-size: 18px;
`;

// const Input = styled.input`
//   height: 35px;
//   width: 100%;
// `;

export default function SubTitleBar({ children }) {
  return <Container>{children}</Container>;
}
