import { Switch, Route } from "react-router-dom";

import FormersSearchListing from "./Listing";
import FormersSearchDetails from "./Details";
import FormersDetailsSearchCreate from "./Create";

function FormersSearch() {
  return (
    <Switch>
      <Route
        exact
        path="/formers/:formersid/search"
        component={FormersSearchListing}
      />
      <Route
        strict
        path="/formers/:formersid/search/create"
        component={FormersDetailsSearchCreate}
      />
      <Route
        strict
        path="/formers/:formersid/search/:searchid"
        component={FormersSearchDetails}
      />
    </Switch>
  );
}

export default FormersSearch;
