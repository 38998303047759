const defaultState = {
  listing: {
    error: false,
    loading: false,
    query: "",
    pageInfo: {
      skip: 0,
      limit: 10,
      total: 0,
    },
    data: [],
  },
  create: {
    error: false,
    loading: false,
    data: {},
  },
  details: {
    information: {
      loading: false,
      pending: false,
      data: {},
    },
    informationUpdate: {
      loading: false,
      pending: false,
      data: {},
    },
    calendar: {
      loading: true,
      pending: false,
      data: [],
    },
    calendarDelete: {
      loading: true,
      pending: false,
      data: [],
    },
    calendarCreate: {
      error: false,
      loading: false,
      data: {},
    },
    formers: {
      error: false,
      loading: false,
      data: [],
    },
    bestChoice: {
      error: false,
      loading: true,
      data: [],
    },
    delete: {
      error: false,
      loading: false,
      data: {},
    },
    search: {
      listing: {
        error: false,
        loading: true,
        data: [],
      },
      formerId: {
        error: false,
        loading: true,
        data: [],
      },
      formerDispo: {
        error: false,
        loading: true,
        data: [],
      },
      create: {
        loading: false,
        error: false,
        data: [],
      },
      select: {
        loading: false,
        error: false,
        data: [],
      },
      selectTime: {
        loading: false,
        error: false,
        data: [],
      },
      resultat: {
        loading: false,
        error: false,
        data: {},
      },
      former: {
        loading: false,
        error: false,
        data: {},
      },
      details: {
        loading: false,
        error: false,
        data: {},
      },
    },
    program: {
      listing: {
        error: false,
        loading: false,
        data: [],
      },
    },
    disponibility: {
      error: false,
      loading: false,
      data: [],
    },
    eventsdisponibility: {
      error: false,
      loading: false,
      data: [],
    },
    update: {
      error: false,
      loading: false,
      data: [],
    },
  },
};

export default function students(state = defaultState, action) {
  switch (action.type) {
    case "GET_STUDENTS_LISTING_LOADING":
      return {
        ...state,
        listing: { ...state.listing, loading: true, error: false },
      };
    case "GET_STUDENTS_LISTING_SUCCESS":
      return {
        ...state,
        listing: { ...state.listing, loading: false, data: action.payload },
      };
    case "GET_STUDENTS_LISTING_ERROR":
      return {
        ...state,
        listing: { ...state.listing, loading: false, error: true },
      };
    case "UPDATE_STUDENTS_LISTING_QUERY":
      return {
        ...state,
        listing: { ...state.listing, query: action.payload },
      };
    case "GET_STUDENTS_CREATE_LOADING":
      return {
        ...state,
        create: { ...state.create, loading: true, error: false },
      };
    case "GET_STUDENTS_CREATE_SUCCESS":
      return {
        ...state,
        create: { ...state.create, loading: false, data: action.payload },
      };
    case "GET_STUDENTS_CREATE_ERROR":
      return {
        ...state,
        create: { ...state.create, loading: false, error: true },
      };
    case "GET_STUDENTS_DETAILS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_UPDATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          informationUpdate: {
            ...state.details.informationUpdate,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_UPDATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          informationUpdate: {
            ...state.details.informationUpdate,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_UPDATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          informationUpdate: {
            ...state.details.informationUpdate,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_DELETE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendarDelete: {
            ...state.details.calendarDelete,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_DELETE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendarDelete: {
            ...state.details.calendarDelete,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_DELETE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendarDelete: {
            ...state.details.calendarDelete,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_CREATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendarCreate: {
            ...state.details.calendarCreate,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_CREATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendarCreate: {
            ...state.details.calendarCreate,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_CREATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendarCreate: {
            ...state.details.calendarCreate,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_FORMERS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          formers: {
            ...state.details.formers,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_FORMERS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          formers: {
            ...state.details.formers,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_FORMERS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          formers: {
            ...state.details.formers,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DELETE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_STUDENTS_DELETE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DELETE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: {
              ...state.details.search.listing,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: {
              ...state.details.search.listing,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: {
              ...state.details.search.listing,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerId: {
              ...state.details.search.formerId,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerId: {
              ...state.details.search.formerId,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerId: {
              ...state.details.search.formerId,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerDispo: {
              ...state.details.search.formerDispo,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerDispo: {
              ...state.details.search.formerDispo,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            formerDispo: {
              ...state.details.search.formerDispo,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_PROGRAM_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          program: {
            ...state.details.program,
            listing: {
              ...state.details.program.listing,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_PROGRAM_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          program: {
            ...state.details.program,
            listing: {
              ...state.details.program.listing,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_PROGRAM_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          program: {
            ...state.details.program,
            listing: {
              ...state.details.program.listing,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_DISPONIBILITY_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          disponibility: {
            ...state.details.disponibility,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_DISPONIBILITY_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          disponibility: {
            ...state.details.disponibility,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_DISPONIBILITY_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          disponibility: {
            ...state.details.disponibility,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          eventsdisponibility: {
            ...state.details.eventsdisponibility,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          eventsdisponibility: {
            ...state.details.eventsdisponibility,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          eventsdisponibility: {
            ...state.details.eventsdisponibility,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_SELECT_FORMER_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            select: {
              ...state.details.search.select,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_SELECT_FORMER_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            select: {
              ...state.details.search.select,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_SELECT_FORMER_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            select: {
              ...state.details.search.select,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_SELECT_FORMER_TIME_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            selectTime: {
              ...state.details.search.selectTime,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_SELECT_FORMER_TIME_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            selectTime: {
              ...state.details.search.selectTime,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_SELECT_FORMER_TIME_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            selectTime: {
              ...state.details.search.selectTime,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_BEST_CHOICE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          bestChoice: {
            ...state.details.bestChoice,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_BEST_CHOICE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          bestChoice: {
            ...state.details.bestChoice,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_BEST_CHOICE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          bestChoice: {
            ...state.details.bestChoice,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            resultat: {
              ...state.details.search.resultat,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            resultat: {
              ...state.details.search.resultat,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            resultat: {
              ...state.details.search.resultat,
              loading: false,
              error: true,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            former: {
              ...state.details.search.former,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            former: {
              ...state.details.search.former,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            former: {
              ...state.details.search.former,
              loading: false,
              error: true,
            },
          },
        },
      };

    case "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          update: {
            ...state.details.update,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          update: {
            ...state.details.update,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          update: {
            ...state.details.update,
            loading: false,
            error: true,
          },
        },
      };
    // case "GET_STUDENTS_DETAILS_SEARCH_DETAILS_LOADING":
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       search: {
    //         ...state.details.search,
    //         listing: {
    //           ...state.details.search.listing,
    //           details: {
    //             ...state.details,
    //             loading: true,
    //             error: false,
    //           },
    //         },
    //       },
    //     },
    //   };
    // case "GET_STUDENTS_DETAILS_SEARCH_DETAILS_SUCCESS":
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       search: {
    //         ...state.details.search,
    //         listing: {
    //           ...state.details.search.listing,
    //           details: {
    //             ...state.details,
    //             loading: false,
    //             data: action.payload,
    //           },
    //         },
    //       },
    //     },
    //   };
    // case "GET_STUDENTS_DETAILS_SEARCH_DETAILS_ERROR":
    //   return {
    //     ...state,
    //     details: {
    //       ...state.details,
    //       search: {
    //         ...state.details.search,
    //         listing: {
    //           ...state.details.search.listing,
    //           details: {
    //             ...state.details,
    //             loading: false,
    //             error: true,
    //           },
    //         },
    //       },
    //     },
    //   };
    case "GET_STUDENTS_DETAILS_SEARCH_CREATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            create: {
              ...state.details.search.create,
              loading: false,
              error: false,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_CREATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            create: {
              ...state.details.search.create,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_STUDENTS_DETAILS_SEARCH_CREATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,

            create: {
              ...state.details.search.create,
              loading: false,
              error: true,
            },
          },
        },
      };

    default:
      return state;
  }
}
