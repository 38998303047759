import { useEffect } from "react";
import { connect } from "../../../../../store";
import Page from "../../../../../Layout/Page";
import { Table, Th } from "../../../../../Common/Table";
import { getFormerDetailsSearchDetails } from "../../../../../store/actions/formers";
import { withRouter } from "react-router-dom";

function FormersSearchDetails({ match, getFormerDetailsSearchDetails }) {
  useEffect(() => {
    getFormerDetailsSearchDetails(
      match.params.formersid,
      match.params.searchid
    );
  }, [
    getFormerDetailsSearchDetails,
    match.params.formersid,
    match.params.searchid,
  ]);

  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Nom</Th>
              <Th style={{ textAlign: "right" }}>Note</Th>
              <Th style={{ textAlign: "right" }}>Proportion</Th>
            </tr>
          </thead>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.formers.details.search.details.data,
      loading: state.formers.details.search.details.loading,
      error: state.formers.details.search.details.error,
      actionWithState: getFormerDetailsSearchDetails.bind(state),
    }),
    { getFormerDetailsSearchDetails }
  )(FormersSearchDetails)
);
