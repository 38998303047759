import styled from "styled-components";

const BubuleContainer = styled.div`
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: rgb(0, 82, 255);
  border-radius: 40px;
`;

const BubuleLabel = styled.span`
  display: inline;
  height: 40px;
  text-align: center;
  line-height: 40px;
  white-space: nowrap;
  margin-left: 10px;
`;

export default function User({ name, lastname }) {
  // const labelName = name
  //   .split(" ")
  //   .filter(Boolean)
  //   .map((v) => v[0].toUpperCase())
  //   .join("");

  const labelName =
    String(name).split(" ").filter(Boolean).join("")[0].toUpperCase() +
    String(lastname).split(" ").filter(Boolean).join("")[0].toUpperCase();

  // const labelName =
  //   name
  //     .split("(")
  //     .filter(Boolean)
  //     .join("")
  //     .split(" ")
  //     .join("")[0]
  //     .toUpperCase() +
  //   lastname
  //     .split("(")
  //     .filter(Boolean)
  //     .join("")
  //     .split(" ")
  //     .join("")[0]
  //     .toUpperCase();

  return (
    <>
      <BubuleContainer>{labelName}</BubuleContainer>
      <BubuleLabel>{name}</BubuleLabel>
      <BubuleLabel>{lastname}</BubuleLabel>
    </>
  );
}
