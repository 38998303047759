import { combineReducers } from "redux";

import auth from "./auth";
import formers from "./formers";
import students from "./students";
import formations from "./formations";
import account from "./account";
import layouts from "./layouts";
import communication from "./communication";
import usersSettings from "./usersSettings";

export default combineReducers({
  account,
  formers,
  students,
  formations,
  layouts,
  communication,
  auth,
  usersSettings,
});
