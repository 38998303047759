const defaultState = {
  sms: {
    error: false,
    loading: false,
    data: {},
  },
};

export default function communication(state = defaultState, action) {
  switch (action.type) {
    case "GET_MESSAGES_LOADING":
      return {
        ...state,
        sms: { ...state.sms, loading: true, error: false },
      };
    case "GET_MESSAGES_SUCCESS":
      return {
        ...state,
        sms: { ...state.sms, loading: false, data: action.payload },
      };
    case "GET_MESSAGES_ERROR":
      return {
        ...state,
        sms: { ...state.sms, loading: false, error: true },
      };
    default:
      return state;
  }
}
