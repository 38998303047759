import { useState, useEffect } from "react";
import {
  Container,
  DayNumber,
  DayLabel,
  Content,
  TimeGrid,
  TimeGridLine,
  TimeGridLineEmpty,
  EventContainer,
  TopContainer,
  CurrentTimeContainer,
  ContentContainer,
  ContentContainer1,
} from "./Styled";

const labelDays = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  7: "Dimanche",
};

const getCurrentDay = function (date) {
  return date.getDate();
};

const getCurrentLabel = function (date) {
  const day = date.getDay();
  return labelDays[day === 0 ? 7 : day];
};

// const getPopUpHour = function (e) {
//   const pourcentage = 7.14 / 100;
//   const borders = e.currentTarget.getBoundingClientRect();
//   const height = borders.height - pourcentage * borders.height;
//   const range = height / 13;
//   const actualRange = Math.trunc((e.clientY - borders.top - 10) / range);
//   const startTime = `${String(actualRange + 8).padStart(2, "0")}:00`;
//   const endTime = `${String(actualRange + 9).padStart(2, "0")}:00`;
//   const result = [startTime, endTime];
//   return result;
// };

const getTopPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTopePosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getToPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getHeight = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrentStart = new Date(event.startDate).getTime();
  const refCurrentEnd = new Date(event.endDate).getTime();
  const eventHeight = refCurrentEnd - refCurrentStart;
  const totalHeight = refEnd - refStart;
  const calcul = (100 * eventHeight) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTimeGrid = function (date, range) {
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setHours(8, 0, 0);
  endDate.setHours(22, 0, 0);
  const numberOfLine = (endDate.getTime() - startDate.getTime()) / range;
  const lines = [...new Array(numberOfLine)].map((_, index) => {
    return new Date(startDate.getTime() + range * index);
  });
  return { startDate, endDate, numberOfLine, lines };
};

function DaySelector({
  currentDate = new Date(),
  displayDayNumber = true,
  displayTime = true,
  displayCurrentTime = false,
  type = "",
  timeGridDisplay = true,
  displayDayLabel = true,
  events = [],
}) {
  // const [datas, setDatas] = useState({
  //   name: "",
  //   instructors: [],
  //   program: [],
  //   dates: [],
  // });

  const [currentTimeContainer, setCurrentTimeContainer] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      new Date().getHours(),
      new Date().getMinutes()
    )
  );
  // const [popUpOn, setPopUpOn] = useState(false);
  // const [date, setDate] = useState(
  //   `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(currentDate.getDate()).padStart(2, "0")}`
  // );

  // const [startTime, setStartTime] = useState("08:00");
  // const [endTime, setEndTime] = useState("09:00");

  // const [interData, setInterData] = useState({
  //   lastname: "",
  //   firstname: "",
  //   formerId: "",
  //   name: "",
  //   programId: "",
  // });

  // const [formerId, setFormerId] = useState("");

  // const handleChange = (path, value) => {
  //   setDatas({ ...datas, [path]: value });
  // };

  // const handleChangeInt = (path, value) => {
  //   setInterData({ ...interData, [path]: value });
  // };

  // const handleSave = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   console.log(interData);
  //   setPopUpOn(false);
  // };

  // const handleHandle = (e) => {
  //   console.log("test", e.target.value);
  //   setFormerId(e.target.value);
  // };

  useEffect(() => {
    window.setInterval(() => {
      setCurrentTimeContainer(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          new Date().getHours(),
          new Date().getMinutes()
        )
      );
    }, 60000);
  }, [currentDate]);

  const currentDay = getCurrentDay(currentDate);
  const currentLabel = getCurrentLabel(currentDate);
  const range = 60000 * 60;
  const grid = getTimeGrid(currentDate, range);

  const now = {
    startDate: currentTimeContainer,
  };

  return (
    <>
      <Container test={displayTime === true}>
        <TopContainer>
          {displayDayNumber && (
            <DayNumber
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth() &&
                currentDate.getFullYear() === new Date().getFullYear()
                  ? "active"
                  : ""
              }
            >
              {currentDay}
            </DayNumber>
          )}
          {displayDayLabel && (
            <DayLabel
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth()
                  ? "active"
                  : ""
              }
            >
              {currentLabel}
            </DayLabel>
          )}
        </TopContainer>
        {timeGridDisplay && (
          <Content>
            <TimeGrid
              onClick={(e) => {
                // setPopUpOn(true)
                const rect = e.currentTarget.getBoundingClientRect();
                console.log(e.clientY - rect.top - 10);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {grid.lines.map((line, index) => (
                <TimeGridLine
                  key={index}
                  style={{
                    top: getTopPosition([grid.startDate, grid.endDate], {
                      startDate: line,
                    }),
                  }}
                >
                  <span>
                    {displayTime &&
                      line.toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </span>
                  <TimeGridLineEmpty />
                </TimeGridLine>
              ))}
            </TimeGrid>
            <>
              {events
                .filter((item) => {
                  return (
                    new Date(item.startDate).getTime() >
                      new Date(grid.startDate).getTime() &&
                    new Date(item.endDate).getTime() <
                      new Date(grid.endDate).getTime()
                  );
                })
                .sort((a, b) => {
                  return (
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime() +
                    (new Date(a.endDate).getTime() -
                      new Date(b.endDate).getTime())
                  );
                })
                .map(
                  (event, index) =>
                    console.log(event.startDate) || (
                      <EventContainer
                        key={index}
                        style={{
                          zIndex: index + 12,
                          top: `calc(${getTopePosition(
                            [grid.startDate, grid.endDate],
                            event
                          )} + (21px / 2) - 1px)`,
                          height: getHeight(
                            [grid.startDate, grid.endDate],
                            event
                          ),
                        }}
                        full={type !== "day"}
                      >
                        <ContentContainer>{event.label}</ContentContainer>
                        <ContentContainer1>
                          {`${new Date(event.startDate).toLocaleString(
                            "fr-FR",
                            { hour: "numeric", minute: "numeric" }
                          )} - ${new Date(event.endDate).toLocaleString(
                            "fr-FR",
                            { hour: "numeric", minute: "numeric" }
                          )}`}
                        </ContentContainer1>
                      </EventContainer>
                    )
                )}
            </>
          </Content>
        )}
      </Container>
      {displayCurrentTime && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: `calc(100% - 80px)`,
            left: "0px",
            top: "65px",
            zIndex: 11,
          }}
          // onClick={(e) => {
          //   setPopUpOn(true);
          //   setStartTime(getPopUpHour(e)[0]);
          //   setEndTime(getPopUpHour(e)[1]);
          //   // e.preventDefault();
          //   // e.stopPropagation();
          // }}
        >
          {/* {popUpOn && (
            <PopUpContainer>
              <Input
                placeholder="Ajouter un titre"
                onChange={(e) => handleChange("name", e.target.value)}
              ></Input>
              {console.log(datat)}
              <SelectorTest
                data={datat}
                label="Selectionner un formateur"
                onChange={(e) => {
                  handleHandle(e);
                }}
              ></SelectorTest>
              <SelectorForm
                label="Selectionner une formation"
                data={data}
                onChange={(e) => {
                  handleChangeInt("name", e.target.value);
                  handleChangeInt("programId", e.target.value);
                }}
              ></SelectorForm>
              <RowContainer>
                <input
                  type="date"
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setPopUpDate(new Date(e.target.value));
                    setDate(
                      `${new Date(e.target.value).getFullYear()}-${String(
                        new Date(e.target.value).getMonth() + 1
                      ).padStart(2, "0")}-${String(
                        new Date(e.target.value).getDate()
                      ).padStart(2, "0")}`
                    );
                  }}
                  value={date}
                  style={{ width: "150px", flex: 1 }}
                />
                <input
                  type="time"
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStartTime(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={startTime}
                  style={{ width: "150px", flex: 1 }}
                />
                <input
                  type="time"
                  value={endTime}
                  style={{ width: "150px", flex: 1 }}
                />
              </RowContainer>
              <Button
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </Button>
            </PopUpContainer>
          )} */}
          <CurrentTimeContainer
            style={{
              top: `calc(${getToPosition(
                [grid.startDate, grid.endDate],
                now
              )} + (21px / 2 ))`,
            }}
            data-time={currentTimeContainer.toLocaleString("fr-fr", {
              hour: "numeric",
              minute: "numeric",
            })}
          ></CurrentTimeContainer>
        </div>
      )}
    </>
  );
}

export default DaySelector;
