const defaultState = {
  listing: {
    loading: false,
    pageInfo: {
      skip: 0,
      limit: 10,
      total: 0,
    },
    data: [],
  },
  details: {
    loading: false,
    pending: false,
    data: {},
  },
};

export default function formers(state = defaultState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
