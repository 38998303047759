import styled from "styled-components";

export default styled.button`
  display: block;
  box-sizing: border-box;
  border: none;
  background: rgb(0, 82, 255);
  color: white;
  height: 35px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-left: 10px;

  &.secondary {
    color: black;
    background: lightgray;
  }

  &:disabled {
    background: transparent;
    border: transparent;
  }
`;
