import TopBar from "../TopBar";
import Content from "../Content";

function Page({ children }) {
  return (
    <>
      <TopBar></TopBar>
      <Content>{children}</Content>
    </>
  );
}

export default Page;
