import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 50%;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 75%;
  flex-direction: column;
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  margin-top: 5px;
  height: 35px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;

  &:disabled {
    background: transparent;
    border: 1px solid transparent;
    color: black;
    padding: 0;
  }
`;
