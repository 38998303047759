import { Route, Switch } from "react-router";
import Tformer from "./Tformer";

function Select() {
  return (
    <Switch>
      {/* <Route
        exact
        path="/students/:studentsid/search/create/:formationsid"
        component={SelectFormer}
      /> */}
      <Route
        exact
        path="/students/:studentsid/search/create/:formationsid"
        component={Tformer}
      />
      {/* <Route
        strict
        path="/students/:studentsid/search/create/:formationsid/:formersid"
        component={SelectCalendar}
      /> */}
    </Switch>
  );
}

export default Select;
