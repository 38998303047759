import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
`;

export const Title = styled.h1`
  margin: 20px 0;
  padding: 0;
`;

export const Row = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
`;

export const NavItem = styled(NavLink)`
  display: flex;
  height: 45px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  margin-bottom: -2px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &.active {
    border-bottom-color: rgb(0, 82, 255);
  }
`;
