import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { login, getUser } from "../../store/actions/auth";
import { connect } from "../../store";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  /* background: rgb(0, 82, 255); */
`;

const SubContainer = styled.div`
  display: flex;
  width: 400px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid grey;
  background: white;
`;

const PictureContainer = styled.div`
  display: flex;
  width: 400px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5%;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  background: ${() =>
    `url("${process.env.PUBLIC_URL}/assets/formationaccess.jpg") no-repeat center center `};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const InputContainer = styled.input`
  display: flex;
  width: 70%;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: 3px;
  margin: 10px;
  &:active {
    border-color: rgb(0, 82, 255);
  }

  &:hover {
    border-color: rgb(0, 82, 255);
  }
`;

const CheckBoxContainer = styled.input`
  width: 25px;
  height: 25px;
  left: 0px;
  overflow: hidden;
  border-radius: 3px;
`;

const ButtonContainer = styled.button`
  display: flex;
  width: 90px;
  height: 30px;
  right: 0%;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 60px; ;
`;

const ValidationContainer = styled.div`
  display: flex;
  width: 288px;
  height: 100px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  /* background: rgb(0, 82, 255); */
`;

const TextContainer = styled.div`
  font-size: 15px;
`;

const LinkContainer = styled(NavLink)`
  display: flex;
  font-size: 15px;
  height: 50px;
  align-items: center;
`;

function Authenticate({ children, data, login, getUser }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleClickConnexion = (e) => {
    e.preventDefault();
    login({
      email,
      password,
    });
  };

  return data ? (
    <>{children}</>
  ) : (
    <Container>
      <PictureContainer></PictureContainer>
      <SubContainer>
        <TextContainer>Connexion</TextContainer>
        <InputContainer
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputContainer
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ValidationContainer>
          <CheckBoxContainer type="checkbox" />
          <TextContainer>Remember me</TextContainer>
          <ButtonContainer onClick={handleClickConnexion}>
            Connexion
          </ButtonContainer>
        </ValidationContainer>
      </SubContainer>
      <LinkContainer to="/forgottenpassword">Forgot password ?</LinkContainer>
    </Container>
  );
}

export default connect(
  (state) => ({
    data: state.auth.data,
  }),
  { login, getUser }
)(Authenticate);

// import { Switch, Route, Redirect } from "react-router-dom";

// import Login from "./Login";
// import ForgottenPassword from "./ForgottenPassword";
// import EmailSent from "./EmailSent";

// function Authenticate() {
//   return (
//     <Switch>
//       <Route path="/" component={Login} />
//       <Route strict path="/forgottenpassword" component={ForgottenPassword} />
//       <Route strict path="/emailsent" component={EmailSent} />
//     </Switch>
//   );
// }

// export default Authenticate;
