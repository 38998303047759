import { useState } from "react";

import Content from "../../../../Layout/Content";
import Form from "../../../../Common/Form";
import Field from "../../../../Common/Form/Field";
import Button from "../../../../Common/Form/Button";

function TrainingsCreate() {
  const [data, setData] = useState({});
  const handleChange = (path, value) => {
    setData({ ...data, [path]: value });
  };

  console.log(data);
  return (
    <>
      <Content>
        <Form>
          <div className="form-row">
            <Field
              label="Nom de la formation"
              onChange={(e) => handleChange("name", e.target.value)}
              value={data.name || ""}
            ></Field>
            <Field
              label="Type de formation"
              onChange={(e) => handleChange("name", e.target.value)}
              value={data.name || ""}
            ></Field>
          </div>
          <div className="form-row">
            <Field
              label="Durée de la formation"
              onChange={(e) => handleChange("name", e.target.value)}
              value={data.name || ""}
            ></Field>
            <Field
              label="Tarif de la formation"
              onChange={(e) => handleChange("name", e.target.value)}
              value={data.name || ""}
            ></Field>
          </div>
          <div className="select-row">
            <Button>Valider</Button>
          </div>
        </Form>
      </Content>
    </>
  );
}

export default TrainingsCreate;
