import styled from "styled-components";
import { Container } from "./Styled";

const Input = styled.input`
  width: 90%;
  height: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #f2f4f5;
  margin-top: 10px;
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-evenly;
`;

const Button = styled.button`
  height: 40px;
  width: 42%;
`;

function SelectTimeContainer({ displayCommand, date, setDisplayCommand }) {
  const handleClick = (e) => {
    e.preventDefault();
    setDisplayCommand(!displayCommand);
  };

  return (
    <>
      {displayCommand ? (
        <Container>
          <div style={{ marginTop: "10px" }}>{date}</div>
          <Input type="time" />
          <Input type="time" />
          <Container1>
            <Button onClick={(e) => handleClick(e)}>Annuler</Button>
            <Button>Valider</Button>
          </Container1>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default SelectTimeContainer;
