import styled from "styled-components";

export const Table = styled.table`
  border-spacing: 0px 10px;
`;

export const Tr = styled.tr`
  cursor: pointer;
  &:hover {
    td {
      background-color: #efefef;
    }
  }
`;

export const Th = styled.th`
  text-align: left;
  background-color: white;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
`;

export const Td = styled.td`
  background-color: white;
  box-sizing: border-box;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
`;

export const Tj = styled.td`
  background-color: white;
  box-sizing: border-box;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  width: 100%;
  text-align: center;
`;

export const Row = styled.div`
  display: "flex";
  flex-direction: "row";
  box-sizing: "border-box";
`;
