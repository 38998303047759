import styled from "styled-components";

export const Container = styled.div`
  width: 30%;
  overflow: visible;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input`
  flex: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: 5px;
`;

export const Button = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
