import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  height: 45px;
  width: 45px;
  background-color: rgb(0, 82, 255);
  border-radius: 45px;
  position: fixed;
  bottom: 45px;
  right: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: 45px;
  font-size: 20px;
  z-index: 9999;
  overflow: hidden;
  * {
    z-index: 1;
  }
  &:after {
    z-index: 0;
    content: "";
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover:after {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
