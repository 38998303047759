import { connect } from "../../../../../../../store";
import { useEffect, useState } from "react";
import { Table, Td, Th, Tr, Row } from "../../../../../../../Common/Table";
import User from "../../../../../../../Common/User";
import Page from "../../../../../../../Layout/Page";
import SearchBar from "../../../../../../../Layout/SearchBar";
import { getBestChoice } from "../../../../../../../store/actions/students";
import { getStudentDetailsSearchSelectFormerFormer } from "../../../../../../../store/actions/students";
import { withRouter } from "react-router";
import styled from "styled-components";

const Container = styled.div`
  width: 30%;
  overflow: visible;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin-right: 5px;
`;

const Input = styled.input`
  flex: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: 5px;
`;

const Button = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

function timeMatch(monthTime, dataDispo, straightHours, total) {
  const letters = ["a", "b", "c", "d"];
  let time = 99;
  let note = 0;
  letters.forEach((letter, indexWeek) => {
    if (monthTime) {
      monthTime[letter]?.map((day, indexDay) => {
        let compteur = 0;
        return day?.map((hour, indexHour) => {
          if (hour === false && dataDispo[indexDay][indexHour] === false) {
            compteur = compteur + 1;
            if (compteur < straightHours + 1) {
              note = note + 1;
              if (note === total) {
                time = indexWeek * 7 + indexDay + 1;
                // console.log("time : ", time, straightHours);
              }
            }
          }

          return time;
        });
      });
    }
  });
  return { time: time, note: note };
}

function TFormer({
  match,
  getBestChoice,
  data,
  getStudentDetailsSearchSelectFormerFormer,
  history,
}) {
  const [liste, setListe] = useState([]);
  const [interStraightHours, setInterStraightHours] = useState(2);
  const [interTotal, setInterTotal] = useState(12);
  let finalData = [];

  const handleClickTotal = () => {
    finalData.length = 0;

    data.data.map((former) => {
      finalData.push({
        lastname: `${former.lastname}`,
        firstname: `${former.firstname}`,
        id: `${former.id}`,
        data: timeMatch(
          data.dataTime[former.id],
          data.dataDispo,
          Number(interStraightHours),
          Number(interTotal)
        ),
      });
      return finalData;
    });
    setListe(finalData);
  };

  const handleSelect = (item) => {
    getStudentDetailsSearchSelectFormerFormer(
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      },
      { formerid: item.id }
    );
    history.go(-2);
  };

  useEffect(() => {
    getBestChoice(
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      },
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      }
    );
  }, [getBestChoice, match.params.studentsid, match.params.formationsid]);

  return (
    <>
      <Page>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SearchBar style={{ display: "flex", flex: "1" }}></SearchBar>
          <Container>
            <Label>Total d'heures</Label>
            <Input
              type={"number"}
              onChange={(e) => setInterTotal(e.target.value)}
            ></Input>
            <Button onClick={() => handleClickTotal()}>Valider</Button>
          </Container>
          <Container>
            <Label>Heures à la suite</Label>
            <Input
              type={"number"}
              onChange={(e) => setInterStraightHours(e.target.value)}
            ></Input>
            <Button onClick={() => handleClickTotal()}>Valider</Button>
          </Container>
        </Row>
        <Table>
          <thead>
            <tr>
              <Th>Nom</Th>
              <Th style={{ textAlign: "center" }}>Sessions</Th>
              <Th style={{ textAlign: "right" }}>Durée</Th>
            </tr>
          </thead>
          <tbody>
            {liste
              .sort((a, b) => {
                return a.data.time - b.data.time;
              })
              ?.map((item) => (
                <Tr onClick={() => handleSelect(item)} key={`${item.id}`}>
                  <Td>
                    <User name={item.firstname} lastname={item.lastname} />
                  </Td>
                  <Td style={{ textAlign: "center" }}>{item.data.note}</Td>
                  {item.data.time === 99 && (
                    <Td style={{ textAlign: "right" }}>+ 1 mois</Td>
                  )}
                  {!(item.data.time === 99) && (
                    <Td style={{ textAlign: "right" }}>{item.data.time}</Td>
                  )}
                </Tr>
              ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.bestChoice.data,
      loading: state.students.details.bestChoice.loading,
      error: state.students.details.bestChoice.error,
    }),
    {
      getBestChoice,
      getStudentDetailsSearchSelectFormerFormer,
    }
  )(TFormer)
);
