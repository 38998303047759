import { Container, NavItem, Row, Title } from "./Styled";
import routing from "../../routing";
import { withRouter } from "react-router";

function TopBar({ match }) {
  const route = routing[match.path];
  // const [connected, setConnected] = useState("loading");

  const navItems = route
    ? route.navItems.map((item) => {
        const params = Object.keys(match.params);
        const path = params.reduce((acc, param) => {
          return acc.replace(`:${param}`, match.params[param]);
        }, item.path);
        return { ...item, path };
      })
    : [];

  return (
    <Container
      style={{
        background: `url("${process.env.PUBLIC_URL}/assets/formationaccess.jpg") no-repeat  `,
        backgroundSize: "contain",
        backgroundPosition: "right",
      }}
    >
      <Row>
        <Title>{(route || {}).title}</Title>
        {/* {connected === "notconnected" && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "90px",
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              background: "rgb(253,1,0)",
            }}
          />
        )}
        {connected === "connected" && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "90px",
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              background: "rgb(49,159,78)",
            }}
          />
        )} */}
      </Row>
      <Row>
        {navItems.map((item, i) => (
          <NavItem key={item.id} to={item.path} exact={!!item.exact}>
            {item.label}
          </NavItem>
        ))}
      </Row>
    </Container>
  );
}

export default withRouter(TopBar);
