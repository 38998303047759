import { useEffect, useState } from "react";
import Page from "../../../../Layout/Page";
import { FormContainer } from "../../../../Common/Form/FormContainer";
import Selector from "../../../../Common/Form/Selector";
import SubTitleBar from "../../../../Common/Form/SubTitleBar";
import FieldText from "../../../../Common/Form/FieldText";
import Button from "../../../../Common/Form/Button";
import {
  LeftContainer,
  Container,
  RightContainer,
  VariableContainer,
} from "./Styled";
import Icon from "../../../../Common/Icon";
import { connect } from "../../../../store";
import {
  getStudentDetails,
  getStudentDelete,
} from "../../../../store/actions/students";
import { getStudentDetailsUpdate } from "../../../../store/actions/students";

function StudentsDetail({
  data,
  match,
  getStudentDetails,
  getStudentDelete,
  getStudentDetailsUpdate,
  history,
}) {
  const [student, setStudent] = useState(data);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getStudentDetails(match.params.studentsid, {});
  }, [getStudentDetails, match.params.studentsid]);

  useEffect(() => {
    if (data._id) {
      setStudent(data);
    }
  }, [data._id, data]);

  const handleChange = (fieldName) => (e) => {
    const value = e.target.value;
    setStudent({ ...student, [fieldName]: value });
  };

  const handleClickDelete = (e) => {
    e.preventDefault();
    console.log("delete");
    getStudentDelete(match.params.studentsid, {});
    history.push(`/students`);
  };

  const handleClickValidate = (e) => {
    e.preventDefault();
    getStudentDetailsUpdate(match.params.studentsid, {
      firstname: student.firstname || "",
      lastname: student.lastname || "",
      email: student.email || "",
      birthCity: student.birthCity || "",
      birthdate: student.birthdate || "",
      birthRegion: student.birthRegion || "",
      roadAddress: student.roadAddress || "",
      cityCode: student.cityCode || "",
      civility: student.civility || "",
      city: student.city || "",
      country: student.country || "",
      phone: student.phone || "",
    });
    setDisabled(!disabled);
  };

  const civility = ["-", "M", "Mme", "Mlle"];

  return (
    <>
      {console.log("student : ", student)}
      <Page>
        <FormContainer>
          <div className="form-row space-between">
            <SubTitleBar>Informations personnels</SubTitleBar>
            <Icon
              name={disabled ? "edit" : "cancel"}
              className="button-edit"
              onClick={() => setDisabled(!disabled)}
            />
          </div>
          <Container>
            <LeftContainer>
              <div className="form-row">
                <VariableContainer style={{ flex: 0.3 }}>
                  <Selector
                    select={student.civility}
                    label="Civilité"
                    data={civility}
                    disabled={disabled}
                  />
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={student.lastname}
                    onChange={handleChange("lastname")}
                    label="Nom :"
                    fieldLabel="Nom"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={student.firstname}
                    onChange={handleChange("firstname")}
                    label="Prénom :"
                    fieldLabel="Prénom"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 3 }}>
                  <FieldText
                    value={student.email}
                    onChange={handleChange("email")}
                    label="Email :"
                    fieldLabel="Email"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={student.code}
                    onChange={handleChange("code")}
                    label="Code interne :"
                    fieldLabel="Code interne"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <FieldText
                  label="Ville de naissance :"
                  fieldLabel="Ville de naissance"
                  value={student.birthCity}
                  onChange={handleChange("birthCity")}
                  disabled={disabled}
                ></FieldText>
                <FieldText
                  value={student.birthRegion}
                  onChange={handleChange("birthRegion")}
                  label="Région de naissance :"
                  fieldLabel="Région de naissance"
                  disabled={disabled}
                ></FieldText>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 0.5 }}>
                  <FieldText
                    label="Date de naissance :"
                    fieldLabel="Date de naissance"
                    value={student.birthdate}
                    onChange={handleChange("birthdate")}
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
            </LeftContainer>
            <RightContainer>
              <div className="form-row">
                <FieldText
                  value={student.roadAddress}
                  onChange={handleChange("roadAddress")}
                  label="Adresse :"
                  fieldLabel="Adresse"
                  disabled={disabled}
                ></FieldText>
              </div>
              <div className="form-row">
                <FieldText
                  label="Code postal :"
                  fieldLabel="Code postal"
                  value={student.cityCode}
                  onChange={handleChange("cityCode")}
                  disabled={disabled}
                ></FieldText>
                <FieldText
                  value={student.city}
                  onChange={handleChange("city")}
                  label="Vile :"
                  fieldLabel="Ville"
                  disabled={disabled}
                ></FieldText>
              </div>
              <div className="form-row">
                <FieldText
                  label="Pays :"
                  fieldLabel="Pays"
                  value={student.country}
                  onChange={handleChange("country")}
                  disabled={disabled}
                ></FieldText>
                <FieldText
                  value={student.phone}
                  onChange={handleChange("phone")}
                  label="Téléphone :"
                  fieldLabel="Téléphone"
                  disabled={disabled}
                ></FieldText>
              </div>
            </RightContainer>
          </Container>
          <div className="form-row">
            <Button disabled={disabled} onClick={(e) => handleClickValidate(e)}>
              Valider
            </Button>
            <Button onClick={handleClickDelete} disabled={disabled}>
              Supprimer
            </Button>
          </div>
        </FormContainer>
      </Page>
    </>
  );
}

export default connect(
  (state) => ({
    data: state.students.details.information.data,
    loading: state.students.details.information.loading,
    error: state.students.details.information.error,
  }),
  { getStudentDetails, getStudentDelete, getStudentDetailsUpdate }
)(StudentsDetail);
