import { Switch, Route } from "react-router-dom";

import StudentsFormersListing from "./Listing";
import StudentsFormersDetails from "./Details";

function StudentsFormers() {
  return (
    <Switch>
      <Route
        exact
        path="/students/:studentsid/formers"
        component={StudentsFormersListing}
      />

      <Route
        strict
        path="/students/:studentsid/formers/:formersid"
        component={StudentsFormersDetails}
      />
    </Switch>
  );
}

export default StudentsFormers;
