import { useState } from "react";
import { getStudentCreate } from "../../../store/actions/students";
import Page from "../../../Layout/Page";
import Form, { Title } from "../../../Common/Form";
import Field from "../../../Common/Form/Field";
import Selector from "../../../Common/Form/Selector";
import Button from "../../../Common/Form/Button";
import { connect } from "../../../store";

function StudentsCreate({ getStudentCreate, data, history }) {
  const [datas, setDatas] = useState({
    firstname: "",
    lastname: "",
    email: "",
    birthCity: "",
    birthdate: "",
    birthRegion: "",
    roadAddress: "",
    cityCode: "",
    civility: "",
    city: "",
    country: "",
    phone: "",
  });
  const handleChange = (path, value) => {
    setDatas({ ...datas, [path]: value });
  };

  const handleClickCreate = (e) => {
    e.preventDefault();
    console.log("create");
    getStudentCreate(datas);
    history.push(`/students`);
  };

  const civility = ["-", "M", "Mme", "Mlle"];

  return (
    <Page>
      <Form>
        <div className="form-row">
          <Title>Informations personnelles</Title>
        </div>
        <div className="form-row">
          <Selector
            label="Civilité"
            data={civility}
            onChange={(e) => handleChange("civility", e.target.value)}
          />
          <Field
            label="Nom"
            onChange={(e) => handleChange("lastname", e.target.value)}
            value={datas.lastname || ""}
          />
          <Field
            label="Prénom"
            onChange={(e) => handleChange("firstname", e.target.value)}
            value={datas.firstname || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Email"
            onChange={(e) => handleChange("email", e.target.value)}
            value={datas.email || ""}
          />
          <Field
            label="Code interne"
            onChange={(e) => handleChange("internalCode", e.target.value)}
            value={datas.internalCode || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Ville de naissance"
            onChange={(e) => handleChange("birthCity", e.target.value)}
            value={datas.birthCity || ""}
          />
          <Field
            label="Département de naissance"
            onChange={(e) => handleChange("birthRegion", e.target.value)}
            value={datas.birthRegion || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Date de naissance"
            onChange={(e) => handleChange("birthdate", e.target.value)}
            value={datas.birthdate || ""}
            type="date"
          />
          <Field disabled={true} />
        </div>
        <div className="form-row">
          <Field
            label="Adresse"
            onChange={(e) => handleChange("roadAddress", e.target.value)}
            value={datas.roadAddress || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Code postal"
            onChange={(e) => handleChange("cityCode", e.target.value)}
            value={datas.cityCode || ""}
          />
          <Field
            label="Ville"
            onChange={(e) => handleChange("city", e.target.value)}
            value={datas.city || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Pays"
            onChange={(e) => handleChange("country", e.target.value)}
            value={datas.country || ""}
          />
          <Field
            label="Téléphone"
            onChange={(e) => handleChange("phone", e.target.value)}
            value={datas.phone || ""}
            type="tel"
          />
        </div>
        <div className="form-row">
          <Selector label="Type de formation"></Selector>
        </div>
        <div className="form-row">
          <Button onClick={handleClickCreate}>Valider</Button>
        </div>
      </Form>
    </Page>
  );
}

export default connect((state) => ({ data: state.auth.data }), {
  getStudentCreate,
})(StudentsCreate);
