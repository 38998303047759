import { Container, Input, Button } from "./Styled";
import { useState } from "react";

function SearchBar({ search, query }) {
  const [searchName, setSearchName] = useState(query);
  const handleSearch = (e) => {
    e.preventDefault();
    search({ query: searchName });
  };
  //Test searchBar responsive
  // const handleSearchBar = (e) => {
  //   setSearchName(e.target.value);
  //   search({ query: searchName });
  // };

  return (
    <Container>
      <Input
        placeholder="Saisir le nom"
        name="searchByName"
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        // onChange={(e) => handleSearchBar(e.target.value)}
      ></Input>
      <Button onClick={handleSearch}>Recherche</Button>
    </Container>
  );
}

export default SearchBar;
