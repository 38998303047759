import { Switch, Route } from "react-router-dom";

import TrainingsCreate from "./Create";
import TrainingsDetails from "./Details";
import TrainingsListing from "./Listing";

function Trainings() {
  return (
    <Switch>
      <Route exact path="/settings/trainings" component={TrainingsListing} />
      <Route
        strict
        path="/settings/trainings/create"
        component={TrainingsCreate}
      />
      <Route
        strict
        path="/settings/trainings/:trainingsid"
        component={TrainingsDetails}
      />
    </Switch>
  );
}

export default Trainings;
