import styled from "styled-components";

export const Tr = styled.tr`
  width: 100%;
  cursor: pointer;
  &:hover {
    td {
      background-color: #efefef;
    }
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Th = styled.th`
  text-align: left;
  background-color: white;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
`;

export const Td = styled.td`
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid rgba(91, 97, 110, 0.2);
  font-weight: 500;
  border-radius: 5px;
  width: 100%;
`;

export const Te = styled.td`
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  width: 100%;
`;

export const Tj = styled.td`
  background-color: white;
  box-sizing: border-box;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  width: 100%;
  text-align: center;
`;

export const Tk = styled.td`
  background-color: white;
  box-sizing: border-box;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  width: 100%;
  text-align: center;
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex: 1; */
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BlockContainer = styled.div`
  /* display: flex; */
  flex-direction: column;
  flex: 1;
  border-left: 2px solid #445a8c;
  height: 450px;
  /* align-items: center; */
  text-align: center;
  /* justify-content: flex-start; */
`;

export const Title = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  font-weight: 700;
  font-size: 18px;
  color: #445a8c;
`;

export default function Icon({ name, className = "", ...props }) {
  return (
    <div style={{ padding: "10px 0px 0px 0px" }}>
      <span {...props} className={`material-icons ${className}`}>
        {name}
      </span>
    </div>
  );
}
