import Page from "../../../../Layout/Page";
import Form from "../../../../Common/Form";
import SubTitleBar from "../../../../Common/Form/SubTitleBar";
import Field from "../../../../Common/Form/Field";

function TrainingsDetails() {
  return (
    <>
      <Page>
        <Form>
          <div className="form-row">
            <SubTitleBar>Settings Trainings Detail</SubTitleBar>
          </div>
          <div className="form-row">
            <Field label="Training :"></Field>
            <Field label="Description :"></Field>
          </div>
        </Form>
      </Page>
    </>
  );
}

export default TrainingsDetails;
