import { Switch, Route } from "react-router-dom";

import StudentsSearchListing from "./Listing";
// import StudentsSearchDetails from "./Details";
import StudentsSearchCreateCalendar from "./Create/Calendar";
import StudentsSearchCreate from "./Create";

function StudentsSearch() {
  return (
    <Switch>
      <Route
        exact
        path="/students/:studentsid/search"
        component={StudentsSearchListing}
      />
      <Route
        strict
        path="/students/:studentsid/search/create"
        component={StudentsSearchCreate}
      />
      <Route
        strict
        path="/students/:studentsid/search/:searchid"
        component={StudentsSearchCreateCalendar}
      />
      {/* <Route
        strict
        path="/students/:studentsid/search/:searchid"
        component={StudentsSearchDetails}
      /> */}
    </Switch>
  );
}

export default StudentsSearch;
