import { useEffect, useState } from "react";
import Page from "../../../Layout/Page";
import Form from "../../../Common/Form";
import { Td, Te, Tk } from "./Styled";
import FieldTextArea from "../../../Common/Form/FieldTextArea";
import FieldLabel from "../../../Common/Form/FieldLabel";
import Button from "../../../Common/Form/Button";
import {
  VariableContainer,
  BlockContainer,
  Title,
  LabelContainer,
} from "./Styled";
import Icon from "./Styled";
import { connect } from "../../../store";
import {
  getFormationDetails,
  getFormationDelete,
} from "../../../store/actions/formations";

function FormationsDetails({
  history,
  data,
  loading,
  match,
  getFormationDetails,
  getFormationDelete,
}) {
  const [formation, setFormation] = useState(data);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getFormationDetails(match.params.formationsid, {});
  }, [getFormationDetails, match.params.formationsid]);

  useEffect(() => {
    if (data.id) {
      setFormation(data);
    }
  }, [data.id, data]);

  const handleClickDelete = (e) => {
    e.preventDefault();
    console.log("delete");
    getFormationDelete(match.params.formationsid, {});
    history.push(`/formations`);
  };

  return (
    console.log(match.params.formationsid) || (
      <Page>
        <Form style={{ background: "rgb(91, 97, 110, 0)" }}>
          {loading && (
            <>
              <div
                style={{
                  textAlign: "center",
                  top: "40%",
                  position: "relative",
                  fontSize: "30px",
                }}
              >
                Chargement
              </div>
            </>
          )}
          {!loading && (
            <>
              <div className="form-row space-between">
                <Title>{formation.name}</Title>
                <Icon
                  name={disabled ? "edit" : "cancel"}
                  className="button-edit"
                  onClick={() => setDisabled(!disabled)}
                />
              </div>
              {/* <div className="form-row">
                <span
                  style={{
                    marginLeft: "15px",
                    borderTop: "1px solid #445a8c",
                    color: "#445a8c",
                    fontWeight: "500",
                  }}
                >
                  {formation.room.name}
                </span>
              </div> */}
              <div className="form-row">
                <VariableContainer style={{ flex: 1 }}>
                  <FieldTextArea
                    label="Description de la formation : "
                    fieldLabel="Description de la formation"
                    value={formation.description}
                    disabled={disabled}
                    height="75px"
                    type="textarea"
                  ></FieldTextArea>
                </VariableContainer>
              </div>
              <div className="form-row space-between">
                <Title>OBJECTIFS PEDAGOGIQUES</Title>
              </div>
              {formation.goals?.map((item, index) => (
                <div className="form-row">
                  <VariableContainer style={{ flex: 1 }}>
                    <Icon name="done" style={{ color: "#3498db" }}></Icon>
                    <Te key={`${index}`}>{item.text}</Te>
                  </VariableContainer>
                </div>
              ))}
              <div className="form-row space-between">
                <Title>PROFIL DES APPRENANTS</Title>
              </div>
              <div className="form-row">
                <FieldLabel
                  color="#3498db"
                  name="person"
                  label="Pour qui : "
                  value="Formation ouverte à tout public"
                  disabled={disabled}
                ></FieldLabel>
                <FieldLabel
                  color="#3498db"
                  name="edit"
                  label="Pré-requis : "
                  value="Savoir rédiger sur clavier pour l'envoi d'e-mails ou traitement de texte."
                  disabled={disabled}
                ></FieldLabel>
              </div>
              <div className="form-row space-between">
                <Title>CONTENU DE LA FORMATION</Title>
              </div>
              {formation.steps?.map((item, index) => (
                <div className="form-row">
                  <VariableContainer style={{ flex: 1 }}>
                    <Td key={`${index}`} style={{ backgroundColor: "#dfe1e6" }}>
                      {item.text}
                    </Td>
                  </VariableContainer>
                </div>
              ))}
              <div className="form-row">
                <BlockContainer>
                  <Icon name="diversity_1" style={{ color: "#445a8c" }}></Icon>
                  <Title>Equipe Pédagogique</Title>
                  <FieldTextArea
                    value={formation.mentoring}
                    disabled={disabled}
                    type="textarea"
                    height="380px"
                  ></FieldTextArea>
                </BlockContainer>
                <BlockContainer>
                  <span
                    class="material-symbols-outlined"
                    style={{ color: "#445a8c", padding: "10px 0px 0px 0px" }}
                  >
                    oven_gen
                  </span>
                  <Title>
                    Suivi de l'execution et évaluation des résultats
                  </Title>
                  {formation.assessments?.map((item, index) => (
                    <div className="form-row" style={{ width: "100%" }}>
                      <LabelContainer>
                        <Icon
                          name="arrow_forward_ios"
                          style={{ color: "#3498db" }}
                        ></Icon>
                        <Tk key={`${index}`}>{item.text}</Tk>
                      </LabelContainer>
                    </div>
                  ))}
                </BlockContainer>
                <BlockContainer style={{ borderRight: "2px solid #445a8c" }}>
                  <Icon name="biotech" style={{ color: "#445a8c" }}></Icon>
                  <Title>Ressources techniques et pédagogiques</Title>
                  {formation.pedagogicalResources?.map((item, index) => (
                    <div className="form-row" style={{ width: "100%" }}>
                      <LabelContainer>
                        <Icon
                          name="arrow_forward_ios"
                          style={{ color: "#3498db" }}
                        ></Icon>
                        <Tk key={`${index}`}>{item.text}</Tk>
                      </LabelContainer>
                    </div>
                  ))}
                </BlockContainer>
              </div>
              <div className="form-row space-between">
                <Button onClick={handleClickDelete} disabled={disabled}>
                  Supprimer
                </Button>
              </div>
            </>
          )}
        </Form>
      </Page>
    )
  );
}

export default connect(
  (state) => ({
    data: state.formations.details.information.data,
    loading: state.formations.details.information.loading,
    error: state.formations.details.information.error,
  }),
  { getFormationDelete, getFormationDetails }
)(FormationsDetails);
