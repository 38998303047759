import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
  margin-top: 5px;
`;

const Label = styled.div`
  flex: 1 1 calc(100% / 4);
  max-width: calc(100% / 4);
  display: flex;
  height: 33, 3%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  &.active {
    color: white;
    background: red;
  }
`;

const labelMonths = {
  1: "Janvier",
  2: "Février",
  3: "Mars",
  4: "Avril",
  5: "Mai",
  6: "Juin",
  7: "Juillet",
  8: "Aout",
  9: "Septembre",
  10: "Octobre",
  11: "Novembre",
  12: "Décembre",
};

function openDetail(day, setCurrentDate, setCurrentView) {
  setCurrentView("month");
  setCurrentDate(day.month);
}

function matchMonth(index) {
  var myCurrentDate = new Date();
  var myFutureDate = new Date(myCurrentDate);
  myFutureDate.setMonth(myFutureDate.getMonth() + index);
  return myFutureDate;
}

function Year({ currentDate = new Date(), setCurrentDate, setCurrentView }) {
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const daysLabelMap = [...new Array(12)].map((_, index) => {
    const date = new Date(currentYear, currentMonth + index - 1);
    return {
      id: index + 1,
      label: `${index + 1}`,
      fullDate: date,
      month: matchMonth(index - 1),
      year: date.getFullYear(),
    };
  });
  return (
    <Container>
      {daysLabelMap.map((day, index) => (
        <Label
          key={day.id}
          className={day.id - 1 === currentMonth ? "active" : ""}
          onClick={() => openDetail(day, setCurrentDate, setCurrentView)}
        >
          {labelMonths[index + 1]}
        </Label>
      ))}
    </Container>
  );
}

export default Year;
