import styled from "styled-components";
import Icon from "../Icon";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SubContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0px 10px;
  font-size: 16px;
`;

const Input = styled.textarea`
  margin-top: 5px;
  height: 40px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 15px;
  overflow-wrap: unset;

  &:disabled {
    background: transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    color: black;
    padding: 0 10px;
  }
`;

export default function FieldLabel({
  label,
  fieldLabel,
  onChange,
  value,
  disabled,
  name,
  type,
  color,
}) {
  return (
    <Container>
      {/* <div style={{ padding: "0px 10px" }}> */}
      <SubContainer>
        <Icon
          name={name}
          style={{ padding: "0px 5px 0px 0px", color: color }}
        ></Icon>
        <label> {label}</label>
      </SubContainer>
      {/* </div> */}
      <Input
        onChange={onChange}
        value={!value && disabled ? fieldLabel : value}
        disabled={disabled}
        type={type}
      />
    </Container>
  );
}
