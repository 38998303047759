import { createStore } from "redux";
import { connect as defaultConnect } from "react-redux";
import reducers from "./reducers";

function bindActions(dispatchParams, actions) {
  const actionToBind = Object.keys(actions).reduce(
    (acc, actionKey) => ({
      ...acc,
      [actionKey]: (...params) =>
        actions[actionKey](...params)(...dispatchParams),
    }),
    {}
  );
  return actionToBind;
}

export const connect =
  (state = () => {}, actions = {}) =>
  (component) => {
    return defaultConnect(state, (...dispatchParams) =>
      // console.log(dispatchParams) || bindActions(dispatchParams, actions)
      bindActions(dispatchParams, actions)
    )(component);
  };

export default createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
