import { Switch, Route } from "react-router-dom";

import FormersListing from "./Listing";
import FormersDetails from "./Details";
import FormersCreate from "./Create";

function Formers() {
  return (
    <Switch>
      <Route exact path="/formers" component={FormersListing} />
      <Route strict path="/formers/create" component={FormersCreate} />
      <Route strict path="/formers/:id" component={FormersDetails} />
    </Switch>
  );
}

export default Formers;
