import { withRouter } from "react-router";
import { connect } from "../../../store";
import { useEffect } from "react";
import { getStudents } from "../../../store/actions/students";
import CreateButton from "../../../Common/CreateButton";
import Icon from "../../../Common/Icon";
import { Table, Td, Th, Tr, Tj } from "../../../Common/Table";
import User from "../../../Common/User";
import SearchBar from "../../../Layout/SearchBar";
import Page from "../../../Layout/Page";

function StudentsListing({ history, loading, data, getStudents, query }) {
  useEffect(() => {
    getStudents({ query });
  }, [getStudents, query]);

  return (
    <>
      <Page>
        <SearchBar query={query} search={getStudents}></SearchBar>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}></Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Tj colspan={3}>Chargement</Tj>
                <Tj></Tj>
                <Tj></Tj>
              </Tr>
            )}
            {!loading &&
              data.map((item) => (
                <Tr
                  key={`${item._id}`}
                  onClick={() => history.push(`/students/${item._id}`)}
                >
                  <Td>
                    <User name={item.lastname} lastname={item.firstname} />
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  <Td style={{ textAlign: "right" }}></Td>
                </Tr>
              ))}
          </tbody>
        </Table>
        <CreateButton to="/students/create">
          <Icon name="add" />
        </CreateButton>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      query: state.students.listing.query,
      data: state.students.listing.data,
      loading: state.students.listing.loading,
      error: state.students.listing.error,
      actionWithState: getStudents.bind(state),
    }),
    { getStudents }
  )(StudentsListing)
);
