const defaultState = {
  graph: {
    error: false,
    loading: false,
    data: [],
  },
  accounts: {
    error: false,
    loading: false,
    data: [],
  },
};

export default function layout(state = defaultState, action) {
  switch (action.type) {
    case "GET_DASHBOARD_LOADING":
      return {
        ...state,
        graph: { ...state.graph, loading: true, error: false },
      };
    case "GET_DASHBOARD_SUCCESS":
      return {
        ...state,
        graph: { ...state.graph, loading: false, data: action.payload },
      };
    case "GET_DASHBOARD_ERROR":
      return {
        ...state,
        graph: { ...state.graph, loading: false, error: true },
      };
    case "GET_ACCOUNTS_LOADING":
      return {
        ...state,
        accounts: { ...state.accounts, loading: true, error: false },
      };
    case "GET_ACCOUNTS_SUCCESS":
      return {
        ...state,
        accounts: { ...state.accounts, loading: false, data: action.payload },
      };
    case "GET_ACCOUNTS_ERROR":
      return {
        ...state,
        accounts: { ...state.accounts, loading: false, error: true },
      };
    default:
      return state;
  }
}

// const defaultState = {
//   darkMode: false,
//   topBar: {
//     title: "",
//     navItems: [],
//     backUrl: "",
//   },
// };

// export default function formers(state = defaultState, action) {
//   switch (action.type) {
//     case "SET_TOPBAR_TITLE":
//       return {
//         ...state,
//         topBar: {
//           ...state.topBar,
//           title: action.payload,
//         },
//       };
//     case "SET_TOPBAR_NAVITEMS":
//       return {
//         ...state,
//         topBar: {
//           ...state.topBar,
//           navItems: action.payload,
//         },
//       };
//     case "SET_TOPBAR_BACKURL":
//       return {
//         ...state,
//         topBar: {
//           ...state.topBar,
//           backUrl: action.payload,
//         },
//       };
//     default:
//       return state;
//   }
// }
