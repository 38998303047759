import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 0.3;
  flex-direction: column;
`;

const Select = styled.select`
  margin-top: 5px;
  height: 35px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;

  &:disabled {
    background: transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    color: black;
    padding: 0;
  }
`;

export default function Selector({ label, data, select, disabled, onChange }) {
  return (
    <Container>
      {!disabled && <label> {label}</label>}
      <Select onChange={onChange} disabled={disabled}>
        {data?.map((item) =>
          select === item ? <option>{item}</option> : <option>{item}</option>
        )}
      </Select>
    </Container>
  );
}
