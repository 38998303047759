import { useEffect } from "react";
import { connect } from "../../../../../../store";
import Page from "../../../../../../Layout/Page";
import { getStudentDetailsSearchCreate } from "../../../../../../store/actions/students";
import { Table, Td, Th, Tr } from "../../../../../../Common/Table";
import Item from "../../../../../../Common/Item";
import { withRouter } from "react-router-dom";

function StudentsDetailsSearchCreateListing({
  history,
  loading,
  data,
  match,
  getStudentDetailsSearchCreate,
}) {
  let result = [];

  useEffect(() => {
    getStudentDetailsSearchCreate(match.params.studentsid);
  }, [getStudentDetailsSearchCreate, match.params.studentsid]);

  data?.programs?.map((item) => {
    result.push([item.name, item.id]);
    return result;
  });

  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Formations</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}></Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Td>Loading</Td>
                <Td style={{ textAlign: "right" }}></Td>
                <Td style={{ textAlign: "right" }}></Td>
              </Tr>
            )}
            {!loading &&
              result?.map((item, index) => (
                <Tr
                  key={index}
                  onClick={() =>
                    history.push(
                      `/students/${match.params.studentsid}/search/create/${item[1]}`
                    )
                  }
                >
                  <Td>
                    <Item label={item[0]}></Item>
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  <Td style={{ textAlign: "right" }}></Td>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.create.data,
      loading: state.students.details.search.create.loading,
      error: state.students.details.search.create.error,
      actionWithState: getStudentDetailsSearchCreate.bind(state),
    }),
    { getStudentDetailsSearchCreate }
  )(StudentsDetailsSearchCreateListing)
);
