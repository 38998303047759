import { Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import Account from "./Account";
import Formers from "./Formers";
import Students from "./Students";
import Settings from "./Settings";
import Formations from "./Formations";

function Nav() {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route strict path="/account" component={Account} />
      <Route strict path="/formers" component={Formers} />
      <Route strict path="/students" component={Students} />
      <Route strict path="/formations" component={Formations} />
      <Route strict path="/settings" component={Settings} />
      <Redirect to="/" />
    </Switch>
  );
}

export default Nav;
