import { Switch, Route } from "react-router-dom";

import StudentsListing from "./Listing";
import StudentsDetails from "./Details";
import StudentsCreate from "./Create";

function Students() {
  return (
    <Switch>
      <Route exact path="/students" component={StudentsListing} />
      <Route strict path="/students/create" component={StudentsCreate} />
      <Route strict path="/students/:id" component={StudentsDetails} />
    </Switch>
  );
}

export default Students;
