import axios from "../../../../api";
import { useState } from "react";

import Button from "../../../Common/Form/Button";
import Page from "../../../Layout/Page";
import Form from "../../../Common/Form";
import Field from "../../../Common/Form/Field";
import Selector from "../../../Common/Form/Selector";
import { Title } from "../../../Common/Form";

function AccountCreate() {
  const [datas, setDatas] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "",
  });

  const handleChange = (path, value) => {
    setDatas({ ...datas, [path]: value });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (
      datas.firstname.length > 0 &&
      datas.lastname.length > 0 &&
      datas.email.length > 0 &&
      datas.password.length > 0 &&
      datas.role.length > 0 &&
      datas.role.length < 12
    ) {
      axios.post("/api/account/create", datas);
      console.log("ok");
    } else {
      console.log("error");
    }
  };

  const roles = ["Choisir un rôle", "admin", "commerciaux"];

  return (
    <>
      <Page>
        <Form>
          <div className="form-row">
            <Title>Informations personnelles</Title>
          </div>
          <div className="form-row">
            <Field
              label="Nom"
              onChange={(e) => handleChange("lastname", e.target.value)}
              value={datas.lastname || ""}
            />
            <Field
              label="Prénom"
              onChange={(e) => handleChange("firstname", e.target.value)}
              value={datas.firstname || ""}
            ></Field>
          </div>
          <div className="form-row">
            <Field
              label="Email"
              onChange={(e) => handleChange("email", e.target.value)}
              value={datas.email || ""}
            />
            <Field
              label="Mot de passe"
              onChange={(e) => handleChange("password", e.target.value)}
              value={datas.password || ""}
            ></Field>
          </div>
          <div className="form-row">
            <Selector
              label="Rôle"
              data={roles}
              onChange={(e) => handleChange("role", e.target.value)}
            />
          </div>
          <div className="form-row">
            <Button onClick={(e) => handleCreate(e)}>Créer</Button>
          </div>
        </Form>
      </Page>
    </>
  );
}

export default AccountCreate;
