import { withRouter } from "react-router";
import { connect } from "../../../store";
import { useEffect } from "react";
import { getAccounts } from "../../../store/actions/layouts";
import { Table, Th, Tr, Tj, Td } from "../../../Common/Table";
import User from "../../../Common/User";
import Page from "../../../Layout/Page";

function AccountListing({ getAccounts, data, loading }) {
  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Nom</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}>Rôle</Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Tj colspan={3}>Chargement</Tj>
                <Tj></Tj>
                <Tj></Tj>
              </Tr>
            )}
            {!loading &&
              data.map((item) => (
                <Tr key={`${item._id}`}>
                  <Td>
                    <User
                      name={String(item.lastname).toUpperCase()}
                      lastname={String(item.firstname).replace(
                        String(item.firstname)[0],
                        String(item.firstname)[0].toUpperCase()
                      )}
                    />
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  <Td style={{ textAlign: "right" }}>{item.role}</Td>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.layouts.accounts.data,
      loading: state.layouts.accounts.loading,
      error: state.layouts.accounts.error,
    }),
    { getAccounts }
  )(AccountListing)
);
