import axios from "../../../api";

export function getDashboard(options) {
  return (dispatch) => {
    console.log("api/dashboard");
    dispatch({ type: "GET_DASHBOARD_LOADING" });
    axios
      .post("/api/dashboard", options)
      .then((response) => {
        dispatch({
          type: "GET_DASHBOARD_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_DASHBOARD_ERROR",
        });
      });
  };
}

export function getAccounts() {
  return (dispatch) => {
    console.log("api/accounts");
    dispatch({ type: "GET_ACCOUNTS_LOADING" });
    axios
      .get("/api/accounts")
      .then((response) => {
        dispatch({
          type: "GET_ACCOUNTS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_ACCOUNTS_ERROR",
        });
      });
  };
}
