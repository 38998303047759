import styled from "styled-components";
import Day from "../Day";
import SelectTimeContainer from "../SelectTimeContainer";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
`;

// const EventContainer = styled.span`
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   height: 50%;
//   overflow: hidden;
//   border-radius: 3px;
// `;

const Grille = styled.div`
  flex: 1 1 calc(100% / 7);
  max-width: calc(100% / 7);
  height: calc(100% / 6);
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  border-right: 1px solid rgba(91, 97, 110, 0.2);
  box-sizing: border-box;
  padding: 10px;
  background: white;
  &.notCurrent {
    background-color: LightGray;
  }
`;

// const DayLabel = styled.span`
//   display: block;
//   width: 25px;
//   height: 25px;

//   line-height: 25px;
//   text-align: center;
//   border-radius: 25px;
//   &.active {
//     color: white;
//     background: red;
//   }
// `;

const Label = styled.div`
  flex: 1 1 calc(100% / 7);
  max-width: calc(100% / 7);
  display: flex;
  height: 45px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const labelDays = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  7: "Dimanche",
};

const getDays = function (month, year) {
  return new Date(year, month, 0).getDate();
};

function Month({ currentDate = new Date(), setCurrentDate, setCurrentView }) {
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const nbCurrentDays = getDays(currentMonth, currentYear);
  const nbPrevDays = getDays(currentMonth - 1, currentYear);
  const startMonth = new Date(currentDate);
  startMonth.setDate(1);
  const indexDay = startMonth.getDay() - 1;

  const prevBlock = [...new Array(indexDay >= 0 ? indexDay : 0)]
    .map((_, i) => {
      const date = new Date(currentYear, currentMonth - 2, nbPrevDays - i);
      return {
        label: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        fullDate: date,
        type: "notCurrent",
      };
    })
    .reverse();

  const currentBlock = [...new Array(nbCurrentDays)].map((_, i) => {
    const date = new Date(currentYear, currentMonth - 1, i + 1);
    return {
      label: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      fullDate: date,
      type: "current",
    };
  });
  const nextBlock = [
    ...new Array(42 - prevBlock.length - currentBlock.length),
  ].map((_, i) => {
    const date = new Date(currentYear, currentMonth, i + 1);
    return {
      label: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      fullDate: date,
      type: "notCurrent",
    };
  });

  const block = [...prevBlock, ...currentBlock, ...nextBlock].map((date, i) => {
    return { id: i, ...date };
  });
  return (
    <Container>
      {Object.values(labelDays).map((day, index) => (
        <Label key={index}>{day}</Label>
      ))}
      {block.map((day) => (
        <Grille
          key={day.id}
          className={day.type}
          // onClick={() => openDetail(day, setCurrentDate, setCurrentView)}
          onClick={() => console.log(day.fullDate)}
        >
          {/* <DayLabel
            className={
              day.label === new Date().getDate() &&
              day.month === new Date().getMonth()
                ? "active"
                : ""
            }
          >
            {day.label}
          </DayLabel> */}
          <Day
            currentDate={day.fullDate}
            timeGridDisplay={false}
            displayDayLabel={false}
            type="month"
          ></Day>
        </Grille>
      ))}
      <SelectTimeContainer></SelectTimeContainer>
    </Container>
  );
}

export default Month;
