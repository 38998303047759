import { useState } from "react";

import Form from "../../../../Common/Form";
import Field from "../../../../Common/Form/Field";
import Selector from "../../../../Common/Form/Selector";
import Button from "../../../../Common/Form/Button";
import Page from "../../../../Layout/Page";

function UsersCreate() {
  const [data, setData] = useState({});
  const handleChange = (path, value) => {
    setData({ ...data, [path]: value });
  };
  return (
    <Page>
      <Form>
        <div className="form-row">
          <Field
            label="Nom"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
          <Field
            label="Prénom"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Email"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
          <Field
            label="Mot de passe"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Tarif"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
          <Field
            label="Nature"
            onChange={(e) => handleChange("name", e.target.value)}
            value={data.name || ""}
          />
        </div>
        <div className="select-row">
          <Selector label="Type de formation"></Selector>
        </div>
        <div className="select-row">
          <Button>Ajouter</Button>
        </div>
        <div className="select-row">
          <Button>Valider</Button>
        </div>
      </Form>
    </Page>
  );
}

export default UsersCreate;
