import { withRouter } from "react-router";
import { connect } from "../../store";
import { React, useEffect, useState } from "react";
import Page from "../../Layout/Page";
import { getDashboard } from "../../store/actions/layouts";

///////////////////////////////////
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import SelectorForm from "../../Common/Form/SelectorForm";

const Row = styled.div`
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
////////////////////////////////////
function Dashboard({ getDashboard, data, loading }) {
  const labels = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  useEffect(() => {
    getDashboard(window.localStorage.getItem("token"));
  }, [getDashboard]);

  const [year, setYear] = useState(new Date().getFullYear().toString());

  /////////////////////////////////////////

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "CA",
      },
    },
  };

  const datat = {
    labels,
    datasets: [
      {
        data: data[year],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  ////////////////////////////////////////////
  return (
    <Page>
      {window.localStorage.getItem("role") === "admin" && (
        <>
          {loading && (
            <>
              <div
                style={{
                  textAlign: "center",
                  top: "40%",
                  position: "relative",
                  fontSize: "30px",
                }}
              >
                Chargement
              </div>
            </>
          )}
          {!loading && (
            <div style={{ position: "relative", height: "90%" }}>
              <Row>
                <SelectorForm
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setYear(e.target.value);
                  }}
                  data={["Selectionner une année"].concat(Object.keys(data))}
                ></SelectorForm>
                <span>{`TOTAL ${year} : ${data[year]
                  ?.reduce(function (a, b) {
                    return a + b;
                  })
                  .toLocaleString()}`}</span>
              </Row>
              <Bar options={options} data={datat} />
            </div>
          )}
        </>
      )}
      {window.localStorage.getItem("role") === "commerciaux" && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {console.log(window.localStorage.getItem("token"))}
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "center",
              fontSize: "28px",
            }}
          >
            Bienvenue
          </div>
        </div>
      )}
    </Page>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.layouts.graph.data,
      loading: state.layouts.graph.loading,
      error: state.layouts.graph.error,
    }),
    { getDashboard }
  )(Dashboard)
);
