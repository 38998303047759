import CalendarFormer from "../../../../Common/Calendar/CalendarFormer";
import Page from "../../../../Layout/Page";
import { getFormerDetailsCalendar } from "../../../../store/actions/formers";
import { connect } from "../../../../store";
import { useEffect } from "react";

function FormersCalendar({ match, events, getFormerDetailsCalendar }) {
  useEffect(() => {
    getFormerDetailsCalendar(match.params.formersid);
  }, [getFormerDetailsCalendar, match.params.formersid]);

  return (
    <>
      <Page>
        {console.log(match)}
        <CalendarFormer events={events} selectId={match} type="week" />
        {console.log("events : ", events)}
      </Page>
    </>
  );
}

export default connect(
  (state) => ({
    events: state.formers.details.calendar.data,
    loading: state.formers.details.calendar.loading,
    error: state.formers.details.calendar.error,
  }),
  { getFormerDetailsCalendar }
)(FormersCalendar);
