const defaultState = {
  listing: {
    error: false,
    loading: false,
    data: [],
  },
  liste: {
    error: false,
    loading: false,
    data: {},
  },
  details: {
    information: {
      loading: false,
      pending: false,
      data: {},
    },
    delete: {
      error: false,
      loading: false,
      data: {},
    },
  },
  create: {
    error: false,
    loading: false,
    data: {},
  },
};

export default function formations(state = defaultState, action) {
  switch (action.type) {
    case "GET_FORMATION_LOADING":
      return {
        ...state,
        liste: { ...state.liste, loading: true, error: false },
      };
    case "GET_FORMATION_SUCCESS":
      return {
        ...state,
        liste: { ...state.liste, loading: false, data: action.payload },
      };
    case "GET_FORMATION_ERROR":
      return {
        ...state,
        liste: { ...state.liste, loading: false, error: true },
      };
    case "GET_FORMATIONS_LISTING_LOADING":
      return {
        ...state,
        listing: { ...state.listing, loading: true, error: false },
      };
    case "GET_FORMATIONS_LISTING_SUCCESS":
      return {
        ...state,
        listing: { ...state.listing, loading: false, data: action.payload },
      };
    case "GET_FORMATIONS_LISTING_ERROR":
      return {
        ...state,
        listing: { ...state.listing, loading: false, error: true },
      };
    case "GET_FORMATIONS_CREATE_LOADING":
      return {
        ...state,
        create: { ...state.create, loading: true, error: false },
      };
    case "GET_FORMATIONS_CREATE_SUCCESS":
      return {
        ...state,
        create: { ...state.create, loading: false, data: action.payload },
      };
    case "GET_FORMATIONS_CREATE_ERROR":
      return {
        ...state,
        create: { ...state.create, loading: false, error: true },
      };
    case "GET_FORMATIONS_DELETE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMATIONS_DELETE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMATIONS_DELETE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_FORMATIONS_DETAILS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMATIONS_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            error: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMATIONS_DETAILS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    default:
      return state;
  }
}
