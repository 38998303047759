import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Input = styled.input`
  margin-top: 5px;
  height: 35px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;

  &:disabled {
    background: transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    color: black;
    padding: 0;
  }
`;

export default function FieldText({
  label,
  fieldLabel,
  onChange,
  value,
  disabled,
  type,
}) {
  return (
    <Container>
      {!disabled && <label> {label}</label>}
      <Input
        onChange={onChange}
        value={!value && disabled ? fieldLabel : value}
        disabled={disabled}
        type={type}
      />
    </Container>
  );
}
