import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const Input = styled.textarea`
  margin-top: 5px;
  /* height: 75px; */
  width: 100%;
  font-size: 15px;
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Helvetica Neue", sans-serif;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;
  white-space: normal;
  overflow-wrap: unset;

  &:disabled {
    background: transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    color: black;
    padding: 0 10px;
  }
`;

export default function FieldTextArea({
  label,
  fieldLabel,
  onChange,
  value,
  disabled,
  height,
  type,
}) {
  return (
    <Container>
      {!disabled && <label> {label}</label>}
      <Input
        onChange={onChange}
        value={!value && disabled ? fieldLabel : value}
        disabled={disabled}
        type={type}
        style={{ height: height }}
      />
    </Container>
  );
}
