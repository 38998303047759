import { Switch, Route } from "react-router-dom";

import UsersListing from "./Listing";
import UsersCreate from "./Create";
import UsersDetails from "./Details";

function Users() {
  return (
    <Switch>
      <Route exact path="/settings/users" component={UsersListing} />
      <Route exact path="/settings/users/create" component={UsersCreate} />
      <Route strict path="/settings/users/:usersid" component={UsersDetails} />
    </Switch>
  );
}

export default Users;
