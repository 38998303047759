import styled from "styled-components";
import { useState } from "react";
import DaySelector from "../DaySelector";
import SelectTimeContainer from "../SelectTimeContainer";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
`;

const Grille = styled.div`
  flex: 1 1 calc(100% / 7);
  max-width: calc(100% / 7);
  height: calc(100% / 6);
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  border-right: 1px solid rgba(91, 97, 110, 0.2);
  box-sizing: border-box;
  padding: 10px;
  background: white;
  &.notCurrent {
    background-color: LightGray;
  }
`;

const Label = styled.div`
  flex: 1 1 calc(100% / 7);
  max-width: calc(100% / 7);
  display: flex;
  height: 45px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const labelDays = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  7: "Dimanche",
};

const labelDays1 = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  0: "Dimanche",
};

const labelMonths = {
  0: "Janvier",
  1: "Février",
  2: "Mars",
  3: "Avril",
  4: "Mai",
  5: "Juin",
  6: "Juillet",
  7: "Aout",
  8: "Septembre",
  9: "Octobre",
  10: "Novembre",
  11: "Décembre",
};

const getDays = function (month, year) {
  return new Date(year, month, 0).getDate();
};

function MonthSelector({
  currentDate = new Date(),
  setCurrentDate,
  setCurrentView,
}) {
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const nbCurrentDays = getDays(currentMonth, currentYear);
  const nbPrevDays = getDays(currentMonth - 1, currentYear);
  const startMonth = new Date(currentDate);
  startMonth.setDate(1);
  const indexDay = startMonth.getDay() - 1;

  const [selectTimeOn, setSelectTimeOn] = useState(false);
  const [selectDatePop, setSelectDatePop] = useState("");

  const prevBlock = [...new Array(indexDay >= 0 ? indexDay : 0)]
    .map((_, i) => {
      const date = new Date(currentYear, currentMonth - 2, nbPrevDays - i);
      return {
        label: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        fullDate: date,
        type: "notCurrent",
      };
    })
    .reverse();

  const currentBlock = [...new Array(nbCurrentDays)].map((_, i) => {
    const date = new Date(currentYear, currentMonth - 1, i + 1);
    return {
      label: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      fullDate: date,
      type: "current",
    };
  });
  const nextBlock = [
    ...new Array(42 - prevBlock.length - currentBlock.length),
  ].map((_, i) => {
    const date = new Date(currentYear, currentMonth, i + 1);
    return {
      label: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      fullDate: date,
      type: "notCurrent",
    };
  });

  const block = [...prevBlock, ...currentBlock, ...nextBlock].map((date, i) => {
    return { id: i, ...date };
  });
  return (
    <Container>
      {Object.values(labelDays).map((day, index) => (
        <Label key={index}>{day}</Label>
      ))}
      {block.map((day) => (
        <Grille
          key={day.id}
          className={day.type}
          onClick={() => {
            setSelectTimeOn(!selectTimeOn);
            setSelectDatePop(
              `${labelDays1[day.fullDate.getDay()]} ${day.fullDate.getDate()} ${
                labelMonths[day.fullDate.getMonth()]
              } ${day.fullDate.getFullYear()}`
            );
          }}
        >
          <DaySelector
            currentDate={day.fullDate}
            timeGridDisplay={false}
            displayDayLabel={false}
            type="month"
          ></DaySelector>
        </Grille>
      ))}
      <SelectTimeContainer
        displayCommand={selectTimeOn}
        date={selectDatePop}
        setDisplayCommand={setSelectTimeOn}
      ></SelectTimeContainer>
    </Container>
  );
}

export default MonthSelector;
