import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 500px;
  /* height: 250px; */
  height: 225px;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: white;
  z-index: 1000;
  box-sizing: border-box;
  position: absolute;
  border: solid 1px;
  border-radius: 3px;
  left: calc(50% - 250px);
  top: 50px;
`;
