import { withRouter } from "react-router";
import CreateButton from "../../../../Common/CreateButton";
import Icon from "../../../../Common/Icon";
import { Table, Td, Th, Tr } from "../../../../Common/Table";
import User from "../../../../Common/User";
import Page from "../../../../Layout/Page";

function TrainingsListing({ history }) {
  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>

              <Th style={{ textAlign: "right" }}>Email</Th>
              <Th style={{ textAlign: "right" }}>Type</Th>
              <Th style={{ textAlign: "right" }}>State</Th>
            </tr>
          </thead>
          <tbody>
            <Tr onClick={() => history.push("/settings/trainings/1")}>
              <Td>
                <User name="Train Clark Kent" />
              </Td>

              <Td style={{ textAlign: "right" }}>clack.kent@gmail.com</Td>
              <Td style={{ textAlign: "right" }}>Former</Td>
              <Td style={{ textAlign: "right", color: "green" }}>
                <Icon name="done" />
              </Td>
            </Tr>
          </tbody>
        </Table>
        <CreateButton
          onClick={() => history.push("/settings/trainings/create")}
          // to="/settings/create"
        >
          <Icon name="add" />
        </CreateButton>
      </Page>
    </>
  );
}

export default withRouter(TrainingsListing);
