import axios from "../../../api";

export function getFormation(id, options) {
  return (dispatch, ...args) => {
    // dispatch({ type: "GET_FORMATION_LOADING" });
    console.log("actions");
    axios
      .get(`/api/formation/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_FORMATION_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMATION_ERROR",
        });
      });
  };
}

export function getFormations(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMATIONS_LISTING_LOADING" });
    axios
      .get("/api/formations")
      .then((response) => {
        dispatch({
          type: "GET_FORMATIONS_LISTING_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMATIONS_LISTING_ERROR",
        });
      });
  };
}

export function getFormationDetails(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMATIONS_DETAILS_LOADING" });
    axios
      .get(`/api/formations/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_FORMATIONS_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMATIONS_DETAILS_ERROR",
        });
      });
  };
}

export function getFormationCreate(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMATIONS_CREATE_LOADING" });
    axios
      .post(`/api/formations/create`, { options })
      .then((response) => {
        // console.log(response)
        dispatch({
          type: "GET_FORMATIONS_CREATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: "GET_FORMATIONS_CREATE_ERROR" });
      });
  };
}

export function getFormationDelete(id, options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMATIONS_DELETE_LOADING" });
    axios
      .get(`/api/formations/${id}/delete`)
      .then((response) => {
        dispatch({
          type: "GET_FORMATIONS_DELETE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: "GET_FORMATIONS_DELETE_ERROR" });
      });
  };
}
