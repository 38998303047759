import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import LeftBar from "./Layout/LeftBar";
import Container from "./Layout/Container";
import Authenticate from "./Layout/Authenticate";

import Nav from "./Nav";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Authenticate>
            <LeftBar />
            <Container>
              <Nav />
            </Container>
          </Authenticate>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
