const defaultState = {
  details: {
    error: false,
    loading: false,
    data: {},
  },
  create: {
    error: false,
    loading: false,
    data: {},
  },
};

export default function account(state = defaultState, action) {
  switch (action.type) {
    case "GET_ACCOUNT_CREATE_ALLOWED_LOADING":
      return {
        ...state,
        create: { ...state.create, loading: true, error: false },
      };
    case "GET_ACCOUNT_CREATE_ALLOWED_SUCCESS":
      return {
        ...state,
        create: { ...state.create, loading: false, data: action.payload },
      };
    case "GET_ACCOUNT_CREATE_ALLOWED_ERROR":
      return {
        ...state,
        create: { ...state.create, loading: false, error: true },
      };
    default:
      return state;
  }
}
