import { Switch, Route, Redirect } from "react-router-dom";

import Users from "./Users";
import Trainings from "./Trainings";

function Settings() {
  return (
    <Switch>
      <Route strict path="/settings/users" component={Users} />
      <Route strict path="/settings/trainings" component={Trainings} />
      <Redirect to="/settings/users" />
    </Switch>
  );
}

export default Settings;
