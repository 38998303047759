import { Route, Switch } from "react-router";
import StudentsDetailsSearchCreateListing from "./Listing";
import Select from "./Select";

function StudentsSearchCreate() {
  return (
    <Switch>
      <Route
        exact
        path="/students/:studentsid/search/create"
        component={StudentsDetailsSearchCreateListing}
      />
      <Route
        strict
        path="/students/:studentsid/search/create/:formationsid"
        component={Select}
      />
    </Switch>
  );
}

export default StudentsSearchCreate;
