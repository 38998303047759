import { useState } from "react";
import { getFormationCreate } from "../../../store/actions/formations";
import Form from "../../../Common/Form";
import Page from "../../../Layout/Page";
import Field from "../../../Common/Form/Field";
import { VariableContainer } from "../../Formers/Details/Detail/Styled";
import Selector from "../../../Common/Form/Selector";
import Button from "../../../Common/Form/Button";
import { connect } from "../../../store";
import FormButton from "../../../Common/Form/FormButton";
import Paragraph from "../../../Common/Form/Paragraph";

function FormationsCreate({ history, getFormationCreate, data }) {
  const [datas, setDatas] = useState({
    name: "",
    description: "",
    mentoring: "",
    prerequisites: [],
    goals: [],
    steps: [],
    assessments: [],
    pedagogicalResources: [],
    targets: [],
  });

  const [goals, setGoals] = useState([]);
  const [steps, setSteps] = useState([]);
  const [targets, setTargets] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [pedagogicalResources, setPedagogicalResources] = useState([]);
  const [prerequisites, setPrerequisites] = useState([]);

  const [numberOfGoals, setNumberOfGoals] = useState([]);
  const [numberOfSteps, setNumberOfSteps] = useState([]);
  const [numberOfAssessments, setNumberOfAssessments] = useState([]);
  const [numberOfPedagogicalResources, setNumberOfPedagogicalResources] =
    useState([]);
  const [numberOfPrerequisites, setNumberOfPrerequisites] = useState([]);
  const [numberOfTargets, setNumberOfTargets] = useState([]);

  let numberGoal = [];
  let numberStep = [];
  let numberAssessment = [];
  let numberPedagogicalResource = [];
  let numberPrerequisite = [];
  let numberTarget = [];

  const handleChange = (path, value) => {
    setDatas({ ...datas, [path]: value });
  };

  const handleChangeArray = (path, value) => {
    setGoals({ ...goals, [path]: value });
  };

  const handleChangeStep = (path, value) => {
    setSteps({ ...steps, [path]: value });
  };

  const handleChangeAssessment = (path, value) => {
    setAssessments({ ...assessments, [path]: value });
  };

  const handleChangePedagogicalResource = (path, value) => {
    setPedagogicalResources({ ...pedagogicalResources, [path]: value });
  };

  const handleChangePrerequisite = (path, value) => {
    setPrerequisites({ ...prerequisites, [path]: value });
  };

  const handleChangeTarget = (path, value) => {
    setTargets({ ...targets, [path]: value });
  };

  const handleClickAddGoal = (e) => {
    e.preventDefault();
    numberGoal.push("1");
    setNumberOfGoals(numberOfGoals.concat(numberGoal));
  };

  const handleClickAddStep = (e) => {
    e.preventDefault();
    numberStep.push("1");
    setNumberOfSteps(numberOfSteps.concat(numberStep));
  };

  const handleClickAddAssessment = (e) => {
    e.preventDefault();
    numberAssessment.push("1");
    setNumberOfAssessments(numberOfAssessments.concat(numberAssessment));
  };

  const handleClickAddPedagogicalResource = (e) => {
    e.preventDefault();
    numberPedagogicalResource.push("1");
    setNumberOfPedagogicalResources(
      numberOfPedagogicalResources.concat(numberPedagogicalResource)
    );
  };

  const handleClickAddPrerequisite = (e) => {
    e.preventDefault();
    numberPrerequisite.push("1");
    setNumberOfPrerequisites(numberOfPrerequisites.concat(numberPrerequisite));
  };

  const handleClickAddTarget = (e) => {
    e.preventDefault();
    numberTarget.push("1");
    setNumberOfTargets(numberOfTargets.concat(numberTarget));
  };

  const handleClickCreate = (e) => {
    e.preventDefault();
    let temp = [];
    let semp = [];
    let aemp = [];
    let pemp = [];
    let premp = [];
    let taemp = [];

    for (var i = 0; i < Object.keys(goals).length; i++) {
      console.log(i);
      temp.push(goals[i]);
      datas.goals.push({ text: goals[i] });
    }
    for (var j = 0; j < Object.keys(steps).length; j++) {
      console.log(j);
      semp.push(steps[j]);
      datas.steps.push({ text: steps[j] });
    }
    for (var k = 0; k < Object.keys(assessments).length; k++) {
      console.log(k);
      aemp.push(assessments[k]);
      datas.assessments.push({ text: assessments[k] });
    }
    for (var l = 0; l < Object.keys(pedagogicalResources).length; l++) {
      console.log(l);
      pemp.push(pedagogicalResources[l]);
      datas.pedagogicalResources.push({ text: pedagogicalResources[l] });
    }
    for (var m = 0; m < Object.keys(prerequisites).length; m++) {
      console.log(m);
      premp.push(prerequisites[m]);
      datas.prerequisites.push({ text: prerequisites[m] });
    }
    for (var n = 0; n < Object.keys(targets).length; n++) {
      console.log(n);
      taemp.push(targets[n]);
      datas.targets.push({ text: targets[n] });
    }
    getFormationCreate(datas);
    history.push(`/formations`);
  };

  const type = ["Formation présentielle", "Formation en ligne"];

  return (
    <Page>
      <Form>
        <div className="form-row">
          <Field
            label="Programme de formation"
            onChange={(e) => handleChange("name", e.target.value)}
            value={datas.name || ""}
          />
          <VariableContainer style={{ flex: 0.3 }}>
            <Selector select={type[0]} label="Type de formation" data={type} />
          </VariableContainer>
        </div>
        <div className="form-row">
          <Paragraph
            label="Description"
            onChange={(e) => handleChange("description", e.target.value)}
            value={datas.description || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Objectifs pédagogiques"
            onChange={(e) => handleChangeArray("0", e.target.value)}
            value={goals[0] || ""}
          />
          <FormButton onClick={handleClickAddGoal}>Plus</FormButton>
        </div>
        {numberOfGoals.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) =>
                handleChangeArray(`${index + 1}`, e.target.value)
              }
              value={goals[index + 1] || ""}
            />
          </div>
        ))}
        <div className="form-row">
          <Field
            label="Pour qui"
            onChange={(e) => handleChangeTarget("0", e.target.value)}
            value={targets[0] || ""}
          />
          <FormButton onClick={handleClickAddTarget}>Plus</FormButton>
        </div>
        {numberOfTargets.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) =>
                handleChangeTarget(`${index + 1}`, e.target.value)
              }
              value={targets[index + 1] || ""}
            />
          </div>
        ))}
        <div className="form-row">
          <Field
            label="Pré-requis"
            onChange={(e) => handleChangePrerequisite("0", e.target.value)}
            value={prerequisites[0]}
          />
          <FormButton onClick={handleClickAddPrerequisite}>Plus</FormButton>
        </div>
        {numberOfPrerequisites.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) =>
                handleChangePrerequisite(`${index + 1}`, e.target.value)
              }
              value={prerequisites[index + 1]}
            />
          </div>
        ))}
        <div className="form-row">
          <Field
            label="Contenu de la formation"
            onChange={(e) => handleChangeStep("0", e.target.value)}
            value={steps[0]}
          />
          <FormButton onClick={handleClickAddStep}>Plus</FormButton>
        </div>
        {numberOfSteps.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) => handleChangeStep(`${index + 1}`, e.target.value)}
              value={steps[index + 1]}
            />
          </div>
        ))}
        <div className="form-row">
          <Paragraph
            label="Equipe pédagogique"
            onChange={(e) => handleChange("mentoring", e.target.value)}
            value={datas.mentoring || ""}
          />
        </div>
        <div className="form-row">
          <Field
            label="Suivi de l'éxécution et évaluations des résultats"
            onChange={(e) => handleChangeAssessment("0", e.target.value)}
            value={assessments[0]}
          />
          <FormButton onClick={handleClickAddAssessment}>Plus</FormButton>
        </div>
        {numberOfAssessments.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) =>
                handleChangeAssessment(`${index + 1}`, e.target.value)
              }
              value={assessments[index + 1]}
            />
          </div>
        ))}
        <div className="form-row">
          <Field
            label="Ressources techniques et pédagogiques"
            onChange={(e) =>
              handleChangePedagogicalResource("0", e.target.value)
            }
            value={pedagogicalResources[0]}
          />
          <FormButton onClick={handleClickAddPedagogicalResource}>
            Plus
          </FormButton>
        </div>
        {numberOfPedagogicalResources.map((item, index) => (
          <div className="form-row">
            <Field
              key={index}
              onChange={(e) =>
                handleChangePedagogicalResource(`${index + 1}`, e.target.value)
              }
              value={pedagogicalResources[index + 1]}
            />
          </div>
        ))}

        <div className="form-row">
          <Button onClick={handleClickCreate}>Valider</Button>
        </div>
      </Form>
    </Page>
  );
}

export default connect(
  (state) => ({
    data: state.auth.data,
  }),
  {
    getFormationCreate,
  }
)(FormationsCreate);
