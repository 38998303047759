import styled from "styled-components";

const BubuleContainer = styled.div`
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: rgb(0, 82, 255);
  border-radius: 40px;
`;

const BubuleLabel = styled.span`
  display: inline;
  height: 40px;
  text-align: center;
  line-height: 40px;
  white-space: nowrap;
  margin-left: 10px;
`;

export default function Formation({ name }) {
  const labelName =
    String(name)
      .split("-")[1]
      .split(" ")
      .map((v) => v[0])
      .join("")[0] +
    String(name)
      .split("-")[1]
      .split(" ")
      .map((v) => v[0])
      .join("")[1];

  return (
    <>
      <BubuleContainer>{labelName}</BubuleContainer>
      <BubuleLabel>{name}</BubuleLabel>
    </>
  );
}
