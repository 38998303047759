import { connect } from "../../../store";
import { useEffect } from "react";
import { Table, Th, Td, Tr } from "../../../Common/Table";
import Page from "../../../Layout/Page";
import CreateButton from "../../../Common/CreateButton";
import Icon from "../../../Common/Icon";
import Item from "../../../Common/Item";
import { getFormations } from "../../../store/actions/formations";
import { withRouter } from "react-router";

function FormationsListing({ history, data, getFormations }) {
  useEffect(() => {
    getFormations();
  }, [getFormations]);

  // useEffect(() => {
  //   console.log("useEffect");
  // }, [(data || {}).programs]);

  return (
    <>
      {console.log("data : ", data)}
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Formation</Th>
            </tr>
          </thead>
          <tbody>
            {data.programs?.map((item, index) => (
              <Tr
                key={`${index}`}
                onClick={() => history.push(`/formations/${item.id}`)}
              >
                <Td>
                  <Item label={item.name}></Item>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
        <CreateButton to="/formations/create">
          <Icon name="add" />
        </CreateButton>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.formations.listing.data,
      loading: state.formations.listing.loading,
      error: state.formations.listing.error,
      actionWithState: getFormations.bind(state),
    }),
    { getFormations }
  )(FormationsListing)
);
