import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Conatainer = styled.div`
  width: 60px;
  overflow: hidden;
  height: 100%;
  background-color: rgb(0, 82, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Brand = styled.div`
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  border-radius: 40px;
  margin: 19px 0;
`;
export const Nav = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const NavBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled(NavLink)`
  text-decoration: none;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white !important;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const Item = styled.div`
  text-decoration: none;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: white !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
