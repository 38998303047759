import axios from "../../../api";

export function getFormers(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMERS_LISTING_LOADING" });
    axios
      .get("/api/formers?filter=" + (options.query || ""))
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_LISTING_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_LISTING_ERROR",
        });
      });
    dispatch({
      type: "UPDATE_FORMERS_LISTING_QUERY",
      payload: options.query || "",
    });
  };
}

export function getFormersCalendarOn(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMERS_CALENDAR_ON_LOADING" });
    axios
      .get("/api/formers/calendaron")
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_CALENDAR_ON_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_CALENDAR_ON_ERROR",
        });
      });
  };
}

export function getFormerCreate(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMERS_CREATE_LOADING" });
    axios
      .post(`/api/formers/create`, { options })
      .then((response) => {
        dispatch({ type: "GET_FORMERS_CREATE_SUCCESS" });
        console.log("getFormerCreate");
      })
      .catch(() => {
        dispatch({ type: "GET_FORMERS_CREATE_ERROR" });
      });
  };
}

export function getFormerDelete(id, options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMERS_DELETE_LOADING" });
    axios
      .get(`/api/formers/${id}/delete`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DELETE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: "GET_FORMERS_DELETE_ERROR" });
      });
  };
}

export function getFormerFormationsListing() {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_FORMERS_FORMATIONS_LISTING_LOADING" });
    axios
      .post(`/api/formers/formationslisting`, {})
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_FORMATIONS_LISTING_SUCCESS",
          payload: response.data,
        });
        console.log("getFormerFormationsListing");
      })
      .catch(() => {
        dispatch({ type: "GET_FORMERS_FORMATIONS_LISTING_ERROR" });
      });
  };
}

export function getFormerDetails(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_LOADING" });
    axios
      .get(`/api/formers/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_ERROR",
        });
      });
  };
}

export function getFormerDetailsUpdate(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_UPDATE_LOADING" });
    axios
      .post(`/api/formers/${id}/update`, { options })
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_UPDATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_UPDATE_ERROR",
        });
      });
  };
}

export function getFormerDetailsCalendarId(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_CALENDAR_ID_LOADING" });
    axios
      .get(`/api/formers/${id}/calendarid`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ID_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ID_ERROR",
        });
      });
  };
}

export function getFormerDetailsCalendarIdUpdate(id, calendarId) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_LOADING" });
    axios
      .post(`/api/formers/${id}/calendarid/update`, { calendarId })
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_ERROR",
        });
      });
  };
}

export function getFormerDetailsCalendar(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_CALENDAR_LOADING" });
    axios
      .get(`/api/formers/${id}/calendar`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ERROR",
        });
      });
  };
}

export function getFormerDetailsCalendarAdd(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_CALENDAR_ADD_LOADING" });
    axios
      .get(`/api/formers/${id}/calendar/create`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ADD_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_CALENDAR_ADD_ERROR",
        });
      });
  };
}

export function getFormerDetailsStudents(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_STUDENTS_LOADING" });
    axios
      .get(`/api/formers/${id}/students`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_STUDENTS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_STUDENTS_ERROR",
        });
      });
  };
}

export function getFormerDetailsSearch(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_SEARCH_LOADING" });
    axios
      .get(`/api/formers/${id}/search`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_ERROR",
        });
      });
  };
}

export function getFormerDetailsSearchCreate(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_SEARCH_CREATE_LOADING" });
    axios
      .post(`/api/formers/${id}/search/create`, options)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_CREATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_CREATE_ERROR",
        });
      });
  };
}

export function getFormerDetailsSearchDetails(id, searchid, options) {
  return (dispatch) => {
    dispatch({ type: "GET_FORMERS_DETAILS_SEARCH_DETAILS_LOADING" });
    console.log(id, searchid);
    axios
      .get(`/api/formers/${id}/search/${searchid}`)
      .then((response) => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_FORMERS_DETAILS_SEARCH_DETAILS_ERROR",
        });
      });
  };
}
