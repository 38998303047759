import { Switch, Route } from "react-router-dom";

import FormationsCreate from "./Create";
import FormationsDetails from "./Details";
import FormationsListing from "./Listing";

function Formations() {
  return (
    <Switch>
      <Route exact path="/formations" component={FormationsListing} />
      <Route strict path="/formations/create" component={FormationsCreate} />
      <Route
        strict
        path="/formations/:formationsid"
        component={FormationsDetails}
      />
    </Switch>
  );
}

export default Formations;
