import Form, { Title } from "../../../Common/Form";
import Field from "../../../Common/Form/Field";
import Button from "../../../Common/Form/Button";
import Page from "../../../Layout/Page";
import Icon from "../../../Common/Icon";
import { useState } from "react";

function AccountDetails() {
  const [disabled, setDisabled] = useState(true);
  const [user, setUser] = useState({
    lastname: "",
    firstname: "",
    email: "",
  });

  return (
    <Page>
      <Form className="full">
        <div className="form-row space-between">
          <Title>Information personels</Title>
          <Icon
            name={disabled ? "edit" : "cancel"}
            className="button-edit"
            onClick={() => setDisabled(!disabled)}
          />
        </div>
        <div className="form-row">
          <Field
            label="Nom :"
            disabled={disabled}
            value={user.lastname}
            onChange={(e) => setUser({ ...user, lastname: e.target.value })}
          />
          <Field
            label="Prénom :"
            disabled={disabled}
            value={user.firstname}
            onChange={(e) => setUser({ ...user, firstname: e.target.value })}
          />
        </div>
        <div className="form-row">
          <Field
            label="Email :"
            disabled={disabled}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </div>
        {!disabled && (
          <>
            <div className="form-row space-between">
              <Title>Changer votre mot de passe</Title>
            </div>
            <div className="form-row">
              <Field label="Ancien mot de passe" disabled={disabled} />
              <Field label="Nouveau mot de passe" disabled={disabled} />
              <Field label="Confirmation mot de passe" disabled={disabled} />
              <Button>Change</Button>
            </div>
          </>
        )}
        {!disabled && (
          <div className="form-row right bordered bottom">
            <Button className="secondary">Cancel</Button>
            <Button>Save</Button>
          </div>
        )}
      </Form>
    </Page>
  );
}

export default AccountDetails;
