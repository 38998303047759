import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
const back_url = "https://formationaccess.herokuapp.com";
// const back_url = "https://sleepy-plains-30984.herokuapp.com";

const proxy = (url) => {
  switch (true) {
    case url.includes("/api"):
      return url.replace("/api", back_url);

    default:
      return url;
  }
};

const override = {
  post: (url, ...params) => {
    return axios.post(proxy(url), ...params);
  },
  get: (url, ...params) => {
    return axios.get(proxy(url), ...params);
  },
};

export default override;
