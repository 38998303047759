import { Route, Switch } from "react-router";

import FormersDetail from "./Detail";
import FormersCalendar from "./Calendar";
import FormersSearch from "./Trainings";

function FormersDetails() {
  return (
    <Switch>
      <Route exact path="/formers/:formersid" component={FormersDetail} />
      <Route
        strict
        path="/formers/:formersid/search"
        component={FormersSearch}
      />
      {/* <Route
        strict
        path="/formers/:formersid/students"
        component={FormersStudents}
      /> */}
      <Route
        strict
        path="/formers/:formersid/calendar"
        component={FormersCalendar}
      />
    </Switch>
  );
}

export default FormersDetails;
