import styled from "styled-components";

export default styled.form`
  background-color: white;
  position: relative;

  &.full {
    min-height: 100%;
  }

  .form-row {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    padding: 5px;
    align-items: flex-end;
    & > div {
      padding: 0 15px;
    }

    &.right {
      justify-content: flex-end;
    }
    &.space-between {
      justify-content: space-between;
    }

    &.bordered {
      border-top: 1px solid lightgrey;
    }

    &.bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  .button-edit {
    font-size: 19px;
    cursor: pointer;
    position: relative;
  }
`;

export const Title = styled.h2`
  margin: 0;
`;
