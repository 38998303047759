import { connect } from "../../../../store";
import styled from "styled-components";
import { useEffect, useState } from "react";

import Page from "../../../../Layout/Page";
import Day from "../../../../Common/Calendar/Day";

import {
  getStudentDetailsEventsDisponibility,
  getStudentDetailsCalendarFormerId,
  getStudentDetailsCalendarFormerDispo,
  getStudentDetailsCalendar,
  getStudentDetailsProgram,
} from "../../../../store/actions/students";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const Button = styled.button`
  flex: 1;
  margin-right: 2px;
  width: 150px;
  height: 35px;
`;

const Input = styled.input`
  flex: 1;
  margin-right: 2px;
  width: 150px;
`;

const Select = styled.select`
  flex: 2;
  margin-right: 2px;
  width: 150px;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;

  &:disabled {
    background: transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    color: black;
    padding: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  background: white;
  margin-top: 10px;
  padding: 15px;
  padding-left: calc(38px + 15px);
  box-sizing: border-box;
  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
`;

function getUnavailabilityByDate(data, day) {
  let response = [];
  let morningDate = new Date(day);
  let eveningDate = new Date(day);
  let morningTime = morningDate.setHours(8, 0, 0, 0);
  let eveningTime = eveningDate.setHours(22, 0, 0, 0);
  let morning = "";
  let evening = "";
  if (morningDate.getDate().toString().length < 2) {
    morning =
      morningDate.getFullYear() +
      "-" +
      (morningDate.getMonth() + 1) +
      "-0" +
      morningDate.getDate() +
      "T08:00:00+01:00";
    evening =
      new Date(day).getFullYear() +
      "-" +
      (new Date(day).getMonth() + 1) +
      "-0" +
      new Date(day).getDate() +
      "T22:00:00+01:00";
  } else {
    morning =
      morningDate.getFullYear() +
      "-" +
      (morningDate.getMonth() + 1) +
      "-" +
      morningDate.getDate() +
      "T08:00:00+01:00";
    evening =
      new Date(day).getFullYear() +
      "-" +
      (new Date(day).getMonth() + 1) +
      "-" +
      new Date(day).getDate() +
      "T22:00:00+01:00";
  }
  for (let index = 0; index < data.length; index++) {
    if (new Date(data[index].start).getDate() === day.getDate()) {
      if (
        new Date(data[index].start).getTime() < morningTime &&
        new Date(data[index].end).getTime() < eveningTime
      ) {
        response.push({
          // startDate: new Date(data[index].start).setHours(8, 0, 0, 0),
          startDate: morning,
          endDate: data[index].end,
          push: "1",
        });
      } else if (
        new Date(data[index].start).getTime() > morningTime &&
        new Date(data[index].end).getTime() > eveningTime
      ) {
        response.push({
          startDate: data[index].start,
          endDate: evening,
          push: "2",
        });
      } else if (
        new Date(data[index].start).getTime() > morningTime &&
        new Date(data[index].end).getTime() < eveningTime
      ) {
        response.push({
          startDate: data[index].start,
          endDate: data[index].end,
          push: "3",
        });
      } else if (
        new Date(data[index].start).getTime() < morningTime &&
        new Date(data[index].end).getTime() > eveningTime
      ) {
        response.push({
          startDate: morning,
          endDate: evening,
          push: "4",
        });
      }
    } else if (
      new Date(data[index].start).getTime() < morningTime &&
      new Date(data[index].end).getTime() > eveningTime
    ) {
      if (new Date(day).getDate().toString().length < 2) {
        morning =
          new Date(day).getFullYear() +
          "-" +
          (new Date(day).getMonth() + 1) +
          "-0" +
          new Date(day).getDate() +
          "T08:00:00+01:00";
        evening =
          new Date(day).getFullYear() +
          "-" +
          (new Date(day).getMonth() + 1) +
          "-0" +
          new Date(day).getDate() +
          "T22:00:00+01:00";
      } else {
        morning =
          new Date(day).getFullYear() +
          "-" +
          (new Date(day).getMonth() + 1) +
          "-" +
          new Date(day).getDate() +
          "T08:00:00+01:00";
        evening =
          new Date(day).getFullYear() +
          "-" +
          (new Date(day).getMonth() + 1) +
          "-" +
          new Date(day).getDate() +
          "T22:00:00+01:00";
      }
      response.push({
        startDate: morning,
        endDate: evening,
        push: "5",
      });
    }
  }
  return response;
}

function getActionsByDisplayType(displayType, selectedDate, setSelectedDate) {
  switch (displayType) {
    case "day":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 1);
          setSelectedDate(date);
        },
      };
    case "week":
    default:
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 7);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 7);
          setSelectedDate(date);
        },
      };
  }
}

function StudentsCalendar({
  match,
  dispo,
  nonDispo,
  events,
  programs,
  popProgram,
  loadingDispo,
  loadingNonDispo,
  loadingPrograms,
  loadingEvents,
  loadingPopProgram,
  getStudentDetailsEventsDisponibility,
  getStudentDetailsCalendarFormerId,
  getStudentDetailsCalendarFormerDispo,
  getStudentDetailsCalendar,
}) {
  const [formerId, setFormerId] = useState("");

  const date = new Date();

  const [displayType, setDisplayType] = useState("week");
  const [selectedDate, setSelectedDate] = useState(date);
  const [updateCalendar, setUpdateCalendar] = useState(false);

  const currentIndex = selectedDate.getDay();
  const prevDays = [...new Array(currentIndex)]
    .map((_, i) => {
      const newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate() - i);
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    })
    .reverse();
  const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + (i + 1));
    return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
  });
  const days = [...prevDays, ...nextDays];

  useEffect(() => {
    const currentIndex = selectedDate.getDay();
    const prevDays = [...new Array(currentIndex)]
      .map((_, i) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - i);
        return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
      })
      .reverse();
    const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
      const newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate() + (i + 1));
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    });
    const days = [...prevDays, ...nextDays];

    let a = new Date();
    let b = a.getTime() + 5184000000;
    let c = new Date();
    c.setTime(b);

    getStudentDetailsEventsDisponibility(match.params.studentsid);
    getStudentDetailsCalendarFormerId(match.params.studentsid);
    getStudentDetailsCalendarFormerDispo(match.params.studentsid, {
      formerId: formerId,
      dates: days,
      start: a,
      end: b,
    });
    getStudentDetailsCalendar(match.params.studentsid);
    getStudentDetailsProgram(match.params.studentsid);
  }, [
    getStudentDetailsEventsDisponibility,
    getStudentDetailsCalendarFormerId,
    match.params.studentsid,
    formerId,
    getStudentDetailsCalendar,
    getStudentDetailsCalendarFormerDispo,
    selectedDate,
  ]);

  const nextType = {
    week: "Semaine prochaine",
    day: "Jour suivant",
  };

  const actualType = {
    week: "Semaine actuelle",
    day: "Jour actuel",
  };

  const previousType = {
    week: "Semaine précédente",
    day: " Jour précédent",
  };

  const actions = getActionsByDisplayType(
    displayType,
    selectedDate,
    setSelectedDate
  );

  const handleCurrentDay = () => {
    setSelectedDate(new Date());
  };

  const handleDayDisplay = () => {
    setDisplayType("day");
  };

  const handleWeekDisplay = () => {
    setDisplayType("week");
  };

  const openDetail = (day) => {
    setDisplayType("day");
    setSelectedDate(new Date(day));
  };

  if (updateCalendar) {
    getStudentDetailsCalendar(match.params.studentsid);
    setUpdateCalendar(false);
  }

  return (
    <Page>
      <Container>
        <RowContainer>
          <Button onClick={() => handleDayDisplay()}>Jour</Button>
          <Button onClick={() => handleWeekDisplay()}>Semaine</Button>
          <Button onClick={actions.prev}>{previousType[displayType]}</Button>
          <Button onClick={() => handleCurrentDay()}>
            {actualType[displayType]}
          </Button>
          <Button onClick={actions.next}>{nextType[displayType]}</Button>
          <Input
            type="date"
            onChange={(e) => setSelectedDate(new Date(e.target.value))}
            value={`${selectedDate.getFullYear()}-${String(
              selectedDate.getMonth() + 1
            ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(
              2,
              "0"
            )}`}
          ></Input>
          {loadingPrograms ? (
            <Select></Select>
          ) : (
            <Select
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFormerId(programs[e.target.value]);
              }}
            >
              {["Selectionner une formation"]
                .concat(Object.keys(programs))
                ?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </Select>
          )}
        </RowContainer>
        <Content>
          {/* {displayType === "week" &&
            loadingDispo &&
            loadingNonDispo &&
            loadingEvents && (
              <>
                {days.map((day, index) => (
                  <FlexContainer>
                    <Day
                      currentDate={day.date}
                      displayTime={index === 0}
                      displayCurrentTime={index === 0}
                      type="week"
                      onDay={false}
                      dispo={[]}
                      nonDispo={[]}
                      selectId={match.params.studentsid}
                      popUpPosition="400px"
                    />
                  </FlexContainer>
                ))}
              </>
            )} */}
          {displayType === "week" && (
            // !loadingDispo &&
            // !loadingNonDispo &&
            // !loadingEvents &&
            <>
              {days.map((day, index) => (
                <FlexContainer onClick={() => openDetail(day.date)}>
                  <Day
                    currentDate={day.date}
                    displayTime={index === 0}
                    selectId={match.params}
                    displayCurrentTime={index === 0}
                    type="week"
                    onDay={false}
                    events={events || []}
                    dispo={dispo[day.date.getDay()] || []}
                    program={Object.keys(programs)}
                    nonDispo={getUnavailabilityByDate(nonDispo, day.date) || []}
                    popUpPosition="400px"
                    formerId={formerId}
                    updateCalendar={updateCalendar}
                    setUpdateCalendar={setUpdateCalendar}
                  ></Day>
                </FlexContainer>
              ))}
            </>
          )}
          {displayType === "day" && (
            <Day
              currentDate={selectedDate}
              displayCurrentTime={
                new Date().getDate() === selectedDate.getDate()
              }
              selectId={match.params}
              type="day"
              onDay={true}
              events={events}
              dispo={dispo[selectedDate.getDay()]}
              nonDispo={getUnavailabilityByDate(nonDispo, selectedDate)}
              popUpPosition="calc(50% - 250px)"
              formerId={formerId}
              program={Object.keys(programs)}
              updateCalendar={updateCalendar}
              setUpdateCalendar={setUpdateCalendar}
            ></Day>
          )}
        </Content>
      </Container>
    </Page>
  );
}

export default connect(
  (state) => ({
    dispo: state.students.details.eventsdisponibility.data,
    nonDispo: state.students.details.search.formerDispo.data,
    programs: state.students.details.search.formerId.data,
    events: state.students.details.calendar.data,
    popProgram: state.students.details.program.listing.data,
    loadingDispo: state.students.details.eventsdisponibility.loading,
    loadingNonDispo: state.students.details.search.formerDispo.loading,
    loadingPrograms: state.students.details.search.formerId.loading,
    loadingEvents: state.students.details.calendar.loading,
    loadingPopProgram: state.students.details.program.listing.loading,
  }),
  {
    getStudentDetailsEventsDisponibility,
    getStudentDetailsCalendarFormerId,
    getStudentDetailsCalendarFormerDispo,
    getStudentDetailsCalendar,
    getStudentDetailsProgram,
  }
)(StudentsCalendar);
