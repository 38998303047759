import { Route, Switch } from "react-router";

import StudentsDetail from "./Detail";
import StudentsCalendar from "./Calendar";
import StudentsFormers from "./Formers";
import StudentsSearch from "./Trainings";
import StudentsAvailability from "./Availability";

function StudentsDetails() {
  return (
    <Switch>
      <Route exact path="/students/:studentsid" component={StudentsDetail} />
      <Route
        strict
        path="/students/:studentsid/search"
        component={StudentsSearch}
      />
      <Route
        strict
        path="/students/:studentsid/formers"
        component={StudentsFormers}
      />
      <Route
        strict
        path="/students/:studentsid/calendar"
        component={StudentsCalendar}
      />
      <Route
        strict
        path="/students/:studentsid/availability"
        component={StudentsAvailability}
      />
    </Switch>
  );
}

export default StudentsDetails;
