import Icon from "../../Common/Icon";
import { Brand, Conatainer, Nav, NavBottom, NavItem, Item } from "./Styled";
import { logout } from "../../store/actions/auth";
import { login } from "../../store/actions/google";
import { sendMessage } from "../../store/actions/communication";
import { useState, useEffect } from "react";
import axios from "../../../api";
import { connect } from "../../store";
import { GoogleLogin } from "react-google-login";

function LeftBar({ data, logout, login, sendMessage }) {
  const [goog, setGoog] = useState(false);

  const responseGoogle = (response) => {
    const { code } = response;
    axios.post("/api/create-tokens", { code }).then((response) => {
      console.log("test : ", response.data);
      setGoog(response.data);
    });
    // login(response);
    // console.log(response);
  };

  // const handleMessage = (e) => {
  //   e.preventDefault();
  //   axios.get("/api/sendmessages");
  //   console.log("mail");
  // };

  const responseError = (response) => {
    console.log(response);
  };

  useEffect(() => {
    axios.get("/api/check-token").then((response) => setGoog(response.data));
  }, []);

  return (
    <Conatainer>
      <Brand>FA</Brand>
      <Nav>
        <NavItem exact to="/">
          <Icon name="dashboard" />
        </NavItem>
        <NavItem strict to="/formers">
          <Icon name="school" />
        </NavItem>
        <NavItem strict to="/students">
          <Icon name="local_library" />
        </NavItem>
        <NavItem strict to="/formations">
          <Icon name="folder" />
        </NavItem>
        {/* <NavItem strict to="/sendmessages" onClick={() => sendMessage()}>
          <Icon name="mail" />
        </NavItem> */}
      </Nav>
      <NavBottom>
        {/* <Item onClick={(e) => handleMessage(e)}>
          <Icon name="mail" />
        </Item> */}
        {!goog && (
          <Item>
            <GoogleLogin
              style={{ marginRight: "0px" }}
              clientId="714286949316-ifav26gc62kgvjvd243h0b7loqj6i3dp.apps.googleusercontent.com"
              buttonText=""
              onSuccess={responseGoogle}
              onFailure={responseError}
              cookiePolicy={"single_host_origin"}
              responseType="code"
              accessType="offline"
              prompt="consent"
              scope="openid email profile https://www.googleapis.com/auth/calendar"
            />
          </Item>
        )}
        {/* <Item>
          <Icon name="dark_mode" />
        </Item>
        <NavItem strict to="/settings">
          <Icon name="settings" />
        </NavItem> */}
        {window.localStorage.getItem("role") === "admin" && (
          <NavItem strict to="/account">
            <Icon name="manage_accounts" />
          </NavItem>
        )}
        <NavItem strict to="./" onClick={() => logout()}>
          <Icon name="logout" />
        </NavItem>
      </NavBottom>
    </Conatainer>
  );
}

export default connect(() => ({}), { logout, login, sendMessage })(LeftBar);
