import axios from "../../../api";

export function sendMessage() {
  return (dispatch, ...args) => {
    console.log("api/sendmessages");
    dispatch({ type: "GET_MESSAGES_LOADING" });
    axios
      .get(`/api/sendmessages`)
      .then((response) => {
        dispatch({ type: "GET_MESSAGES_SUCCESS" });
      })
      .catch(() => {
        dispatch({ type: "GET_MESSAGES_ERROR" });
      });
  };
}
