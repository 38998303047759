import styled from "styled-components";
import { getStudentDetailsCalendarFormerDispo } from "../../../store/actions/students";
import Day from "../Day";
import { connect } from "../../../store";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
`;
////Ceci est la vrai fonction
// function getUnavailabilityByDate(data, day) {
//   let response = [];
//   for (let index = 0; index < data.length; index++) {
//     if (new Date(data[index].start).getDate() === day.date.getDate()) {
//       if (
//         (new Date(data[index].start).getHours() < 8 ||
//           new Date(data[index].start).getHours() === 0) &&
//         new Date(data[index].end).getHours() <= 22 &&
//         new Date(data[index].end).getHours() !== 0 &&
//         new Date(data[index].end).getHours() !== 1
//       ) {
//         response.push({
//           startDate: new Date(data[index].start).setHours(8),
//           endDate: data[index].end,
//           push: "1",
//         });
//       }
//       //////////////////////
//       // else if (
//       //   day.date.getTime() > new Date(data[index].start).getTime() &&
//       //   day.date.getTime() < new Date(data[index].end).getTime()
//       // ) {
//       //   response.push({
//       //     startDate: new Date(data[index].start).setHours(8),
//       //     endDate: new Date(data[index].start).setHours(22),
//       //     push: "7",
//       //     test: [
//       //       new Date(data[index].end),
//       //       new Date(data[index].start),
//       //       day.date,
//       //     ],
//       //   });
//       // }
//       ////////////////////////
//       else if (
//         new Date(data[index].start).getHours() >= 8 &&
//         new Date(data[index].start).getHours() !== 0 &&
//         (new Date(data[index].end).getHours() > 22 ||
//           new Date(data[index].end).getHours() === 0) &&
//         new Date(data[index].start).getHours() < 22
//       ) {
//         response.push({
//           startDate: data[index].start,
//           endDate: new Date(data[index].end).setHours(22),
//           push: "2",
//         });
//       } else if (
//         (new Date(data[index].start).getHours() < 8 ||
//           new Date(data[index].start).getHours() === 0 ||
//           new Date(data[index].start).getHours() === 1) &&
//         (new Date(data[index].end).getHours() > 22 ||
//           new Date(data[index].end).getHours() === 0 ||
//           new Date(data[index].start).getHours() === 1)
//       ) {
//         if (
//           new Date(data[index].start).getDate() !==
//           new Date(data[index].end).getDate()
//         ) {
//           for (
//             let i = new Date(data[index].start).getDate();
//             i < new Date(data[index].end).getDate();
//             i++
//           ) {
//             response.push({
//               startDate: new Date(
//                 new Date(data[index].start).getFullYear(),
//                 new Date(data[index].start).getMonth(),
//                 i,
//                 8,
//                 0
//               ),
//               endDate: new Date(
//                 new Date(data[index].start).getFullYear(),
//                 new Date(data[index].start).getMonth(),
//                 i,
//                 22,
//                 0
//               ),
//               push: "5",
//             });
//           }
//         } else {
//           response.push({
//             startDate: new Date(data[index].start).setHours(8),
//             endDate: new Date(data[index].end).setHours(22),
//             push: "3",
//           });
//         }
//       } else {
//         if (new Date(data[index].start).getHours() < 22) {
//           response.push({
//             startDate: data[index].start,
//             endDate: data[index].end,
//             push: "4",
//             test: [
//               new Date(data[index].start).getUTCHours(),
//               new Date(data[index].end).getUTCHours(),
//               new Date(data[index].start).getTimezoneOffset(),
//             ],
//           });
//         }
//       }
//     } else if (
//       new Date(data[index].start).getDate !== day.date.getDate() &&
//       new Date(data[index].end).getTime() > day.date.getTime() &&
//       new Date(data[index].start).getTime() < day.date.getTime()
//     ) {
//       response.push({
//         startDate: new Date(
//           day.date.getFullYear(),
//           day.date.getMonth(),
//           day.date.getDate(),
//           8,
//           0
//         ),
//         endDate: new Date(
//           day.date.getFullYear(),
//           day.date.getMonth(),
//           day.date.getDate(),
//           22,
//           0
//         ),
//         push: "6",
//         test: [
//           new Date(data[index].start).getUTCHours(),
//           new Date(data[index].end).getUTCHours(),
//           new Date(data[index].start).getTimezoneOffset(),
//         ],
//       });
//     }
//   }
//   console.log(response);
//   return response;
// }

function getUnavailabilityByDate(data, day) {
  let response = [];
  let morningDate = new Date(day.date);
  let eveningDate = new Date(day.date);
  let morningTime = morningDate.setHours(8, 0, 0, 0);
  let eveningTime = eveningDate.setHours(22, 0, 0, 0);
  let morning = "";
  let evening = "";
  if (morningDate.getDate().toString().length < 2) {
    morning =
      morningDate.getFullYear() +
      "-" +
      (morningDate.getMonth() + 1) +
      "-0" +
      morningDate.getDate() +
      "T08:00:00+01:00";
    evening =
      new Date(day.date).getFullYear() +
      "-" +
      (new Date(day.date).getMonth() + 1) +
      "-0" +
      new Date(day.date).getDate() +
      "T22:00:00+01:00";
  } else {
    morning =
      morningDate.getFullYear() +
      "-" +
      (morningDate.getMonth() + 1) +
      "-" +
      morningDate.getDate() +
      "T08:00:00+01:00";
    evening =
      new Date(day.date).getFullYear() +
      "-" +
      (new Date(day.date).getMonth() + 1) +
      "-" +
      new Date(day.date).getDate() +
      "T22:00:00+01:00";
  }
  for (let index = 0; index < data.length; index++) {
    if (new Date(data[index].start).getDate() === day.date.getDate()) {
      if (
        new Date(data[index].start).getTime() < morningTime &&
        new Date(data[index].end).getTime() < eveningTime
      ) {
        response.push({
          // startDate: new Date(data[index].start).setHours(8, 0, 0, 0),
          startDate: morning,
          endDate: data[index].end,
          push: "1",
        });
      } else if (
        new Date(data[index].start).getTime() > morningTime &&
        new Date(data[index].end).getTime() > eveningTime
      ) {
        response.push({
          startDate: data[index].start,
          endDate: evening,
          push: "2",
        });
      } else if (
        new Date(data[index].start).getTime() > morningTime &&
        new Date(data[index].end).getTime() < eveningTime
      ) {
        response.push({
          startDate: data[index].start,
          endDate: data[index].end,
          push: "3",
        });
      } else if (
        new Date(data[index].start).getTime() < morningTime &&
        new Date(data[index].end).getTime() > eveningTime
      ) {
        response.push({
          startDate: morning,
          endDate: evening,
          push: "4",
        });
      }
    } else if (
      new Date(data[index].start).getTime() < morningTime &&
      new Date(data[index].end).getTime() > eveningTime
    ) {
      if (new Date(day.date).getDate().toString().length < 2) {
        morning =
          new Date(day.date).getFullYear() +
          "-" +
          (new Date(day.date).getMonth() + 1) +
          "-0" +
          new Date(day.date).getDate() +
          "T08:00:00+01:00";
        evening =
          new Date(day.date).getFullYear() +
          "-" +
          (new Date(day.date).getMonth() + 1) +
          "-0" +
          new Date(day.date).getDate() +
          "T22:00:00+01:00";
      } else {
        morning =
          new Date(day.date).getFullYear() +
          "-" +
          (new Date(day.date).getMonth() + 1) +
          "-" +
          new Date(day.date).getDate() +
          "T08:00:00+01:00";
        evening =
          new Date(day.date).getFullYear() +
          "-" +
          (new Date(day.date).getMonth() + 1) +
          "-" +
          new Date(day.date).getDate() +
          "T22:00:00+01:00";
      }
      response.push({
        startDate: morning,
        endDate: evening,
        push: "5",
      });
    }
  }
  console.log(response, day, data);
  return response;
}

function openDetail(
  day,
  setCurrentDate,
  setCurrentView,
  setNonDispo,
  nonDispo,
  dispo,
  setDispo,
  getUnavailabilityByDate,
  selectId,
  formerId,
  e,
  data
) {
  e.preventDefault();
  e.stopPropagation();
  setCurrentView("day");
  setCurrentDate(day.date);
  setNonDispo(getUnavailabilityByDate(data, day));
  // axios
  //   .post(
  //     `http://localhost:4000/students/${selectId.studentsid}/calendar/formerDispos`,
  //     {
  //       formerId: formerId,
  //       dates: day.date,
  //     }
  //   )
  //   .then((response) => {
  //     console.log(response.data, day);
  //     // setNonDispo(getUnavailabilityByDate(response.data, day));
  //     setNonDispo(response.data);
  //   });
  setDispo(dispo.data[day.date.getDay()]);
}

function Week({
  date = new Date(),
  setCurrentDate,
  setCurrentView,
  setNonDispo,
  nonDispo,
  events,
  dispo,
  setDispo,
  selectId,
  popUpPosition,
  loading,
  data,
  getStudentDetailsCalendarFormerDispo,
  formerId,
}) {
  const currentIndex = date.getDay();
  const prevDays = [...new Array(currentIndex)]
    .map((_, i) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - i);
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    })
    .reverse();

  const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (i + 1));
    return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
  });
  const days = [...prevDays, ...nextDays];

  useEffect(() => {
    const date = new Date();
    const currentIndex = date.getDay();
    const prevDays = [...new Array(currentIndex)]
      .map((_, i) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - i);
        return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
      })
      .reverse();

    const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + (i + 1));
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    });
    const days = [...prevDays, ...nextDays];
    getStudentDetailsCalendarFormerDispo(selectId.toString(), {
      formerId: formerId,
      dates: days,
    });
  }, [getStudentDetailsCalendarFormerDispo, selectId, formerId]);

  return (
    <Container>
      {/* {loading && <span>Loading</span>} */}
      {!loading && (
        <>
          {days.map((day, index) => (
            <Content
              key={day.id}
              onClick={(e) =>
                openDetail(
                  day,
                  setCurrentDate,
                  setCurrentView,
                  setNonDispo,
                  nonDispo,
                  dispo,
                  setDispo,
                  getUnavailabilityByDate,
                  selectId,
                  formerId,
                  e,
                  data
                )
              }
            >
              {/* <DayNumber>{daysMap[index]["date"]}</DayNumber>
            <DayLabel className={day.id === currentIndex ? "active" : ""}>
              {labelDays[index + 1]}
            </DayLabel> */}
              <Day
                currentDate={day.date}
                displayTime={index === 0}
                displayCurrentTime={index === 0}
                type="week"
                onDay={false}
                events={events}
                dispo={dispo.data[day.date.getDay()]}
                selectId={selectId}
                popUpPosition={popUpPosition}
                // nonDispo={data?.map((item) => {
                //   if (new Date(item.start).getDate() === day.date.getDate()) {
                //     return item;
                //   }
                // })}
                nonDispo={getUnavailabilityByDate(data, day)}
                // nonDispo={nonDispo}
                formerId={formerId}
              ></Day>
            </Content>
          ))}
        </>
      )}
    </Container>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.formerDispo.data,
      loading: state.students.details.search.formerDispo.loading,
      error: state.students.details.search.formerDispo.error,
      actionWithState: getStudentDetailsCalendarFormerDispo.bind(state),
    }),
    { getStudentDetailsCalendarFormerDispo }
  )(Week)
);

// function Week() {
//   const daysMap = [...new Array(7)].map((_, index) => ({
//     id: index + 1,
//     label: `${index + 1}`,
//   }));
//   console.log(daysMap[0]);
//   console.log(daysMap, [...new Array(7)]);

//   return (
//     <Container>
//       {daysMap.map((day, index) => (
//         <Day displayTime={index === 0} />
//       ))}
//     </Container>
//   );
// }

// export default Week;

// function Day({ date = new Date() }) {
//   const currentDay = getCurrentDay(date);
//   console.log(currentDay, getCurrentLabel(date), Object.values(labelDays));
//   return (
//     <div>
//       {Object.values(labelDays).map((label) => (
//         <div>{label}</div>
//       ))}
//     </div>
//   );
// }
