import axios from "../../../api";
import defaultAxios from "axios";

export function login(options) {
  return (dispatch, ...args) => {
    dispatch({ type: "LOGIN_LOADING" });
    axios
      .post("/api/auth", options)
      .then((response) => {
        defaultAxios.defaults.headers.common = {
          Authorization: "Bearer " + response.data.token,
        };
        // defaultAxios.defaults.headers.common = {};
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("role", response.data.role);
        // window.localStorage.setItem("token", "");
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: response.data,
          //payload : null
        });
      })
      .catch(() => {
        dispatch({
          type: "LOGIN_ERROR",
        });
      });
  };
}

export function getUser(options) {
  return (dispatch, ...args) => {
    defaultAxios.defaults.headers.common = {
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    };
    dispatch({ type: "GET_USER_LOADING" });
    axios
      .get("/api/user")
      .then((response) => {
        console.log("role : ", response.data.role);
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("role", response.data.role);
        dispatch({
          type: "GET_USER_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_USER_ERROR",
        });
      });
  };
}

export function logout() {
  // console.log(options);
  return (dispatch, ...args) => {
    axios.get("/api/logout").then(() => {
      defaultAxios.defaults.headers.common = {};
      // defaultAxios.defaults.headers.common = {};
      // window.localStorage.setItem("token", response.data.token);
      window.localStorage.removeItem("token");
      dispatch({
        type: "LOGIN_SUCCESS",
        // payload: response.data,
        payload: null,
      });
    });
  };
}
